import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import bpSvgSummary from './svg/SVG_blood-pressure-summary.svg';
// import bgSvgSummary from './svg/SVG_blood-glucose-summary.svg';
import hrSvgSummary from './svg/SVG_hr-levels-summary.svg';
import oxygenSvgSummary from './svg/SVG_oxygen-levels-summary.SVG';
import tempSvgSummary from './svg/SVG_temperature-summary.svg';
import weightSvgSummary from './svg/SVG_weight-summary.svg';

import AmplifySceneLoader, { sceneConfigs } from '../../AmplifySceneLoader';


// var bgSvgSummary = require('./svg/SVG_blood-glucose-summary.svg');

window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();

export default class SummaryButtons extends Component {
	async getInitialState() {
		return {
			on: true,
			glucose: "121",
			currentScene: sceneConfigs.introScene,
			currentSceneName: "introScene"
		}
	}

    constructor( props ) {
        super( props );


		window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();

        this.state = this.getInitialState();

        Hub.listen("PlayingNewScene", this, "SummaryButtons");
        Hub.listen("VitalsTaken", this, "SummaryButtons");
        Hub.listen("GlucoseValue", this, "SummaryButtons");
    }

    async componentDidMount() {
        	this.setState({currentSceneName: window.amplifySceneLoader.state.currentScene.name})
    }

    componentDidUpdate() {

    }

    async onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "GlucoseValue") {
        	console.log("in SummaryButton, GlucoseValue.payload: ", payload);
        	this.setState({glucose: payload.glucose});
        }

        if(channel === "PlayingNewScene" || channel === "VitalsTaken") {
        	console.log("PlayingNewScene detected in SummaryButtons, window.amplifySceneLoader.state.currentScene.name", window.amplifySceneLoader.state.currentScene.name);
        	this.setState({currentSceneName: window.amplifySceneLoader.state.currentScene.name})
        }
    }	

	render() {
		return this.state.currentSceneName !== "vitalsSceneDiabetes" ? (
			<React.Fragment>
			    <button id="hr-levels-summary" className="summary-button">
			    	<img src={hrSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Heart Rate</div>
			        <div id="hr-value-summary" className="result">
			            121 bpm
			        </div>
			    </button>
			    <button className="summary-button" id="oxygen-levels-summary">
			    	<img src={oxygenSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Oxygen Levels</div>
			        <div id="oxygen-value-summary" className="result">
			            97.2%
			        </div>
			    </button>
			    <button className="summary-button" id="blood-pressure-summary">
			    	<img src={bpSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Blood Pressure</div>
			        <div id="bp-value-summary" className="result">
			            135/93
			        </div>
			    </button>
			    <button className="summary-button" id="weight-summary">
			    	<img src={weightSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Weight</div>
			        <div id="weight-value-summary" className="result">
			            201.5 lb
			        </div>
			    </button>
			    <button className="summary-button" id="temperature-summary">
			    	<img src={tempSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Temperature</div>
			        <div id="temperature-value-summary" className="result">
			            100&deg;
			        </div>
			    </button>


			</React.Fragment>
		) : (

			<React.Fragment>
 
				<button className="summary-button" style={{backgroundColor:"#00B54A"}} id="blood-glucose-summary">
			        <img src={"https://s3.amazonaws.com/addison-project-anim-files/UI+Images/SVG_blood-glucose-summary.png"} style={{height:"40px",width:"40px", paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Blood Glucose</div>
			        <div id="bg-value" className="result">
			            {this.state.glucose} mg/dL
			        </div>
			    </button>
			    <button className="summary-button" id="oxygen-levels-summary">
			    	<img src={oxygenSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Oxygen Levels</div>
			        <div id="oxygen-value-summary" className="result">
			            97.2%
			        </div>
			    </button>
			    {/* BP not taken in vitals (Says everything is fine but shows red) */}
{/*			    <button className="summary-button" id="blood-pressure-summary">
			    	<img src={bpSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Blood Pressure</div>
			        <div id="bp-value-summary" className="result">
			            135/93
			        </div>
			    </button>*/}
			    <button className="summary-button" id="weight-summary">
			    	<img src={weightSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Weight</div>
			        <div id="weight-value-summary" className="result">
			            201.5 lb
			        </div>
			    </button>
			    {/* Temp not taken in vitals (Says everything is fine but shows red) */}
{/*			    <button className="summary-button" id="temperature-summary">
			    	<img src={tempSvgSummary} style={{paddingLeft:"1vw"}}  alt=""/>
			        <div className="vital-name">Temperature</div>
			        <div id="temperature-value-summary" className="result">
			            98&deg;
			        </div>
			    </button>*/}


			</React.Fragment>
		)
	}

}

 