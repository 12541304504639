import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class BTN_Yes extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("ShowYes", this);
        Hub.listen("HideYes", this);
        Hub.listen("toggleYes", this);
        Hub.listen("sYN", this);
        Hub.listen("hYN", this);
        Hub.listen("snooze", this);
        Hub.listen("snooze_last", this);
        Hub.listen("YesBtn", this);
        Hub.listen("NoBtn", this);
        Hub.listen("yes", this);
        Hub.listen("no", this);
        Hub.listen("not yet", this);


        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.handleClick = this.handleClick.bind(this);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset" || channel === "SceneReset") {
           this.reset();
        }

        else if(channel === "HideYes" || channel === "YesBtn" || channel === "NoBtn" || channel === "snooze" || channel === "snooze_last")  {
          this.hide();
        }

        else if(channel === "yes" || channel === "no" || channel === "not yet" || channel === "hYN" ) {
            this.hide();
        }

        else if(channel === "sYN" || channel === "ShowYes" ) {
          this.show();
        }

        else if(channel === "toggleYes") {
          this.setState({on: !(this.state.on)});
        }
    }


    handleClick(event) {
      event.preventDefault();
      this.hide();
      Hub.dispatch("HideNo");
      Hub.dispatch("HideSnooze");
      // window.amplifySceneLoader.emit("post_to_lex", "yes");
      window.amplifySceneLoader.emit("post_to_lex", "Yes");
    }


    render() {
        return (

            <button className={this.state.on ? "btnLarge" : "hidden"} onClick={this.handleClick} id="btnyes">

                <svg
                    viewBox="0 0 442.533 442.533"
                    width="4vw"
                    height="4vw"
                    overflow="visible"
                >
                    <g transform="translate(-600,25)">
                        <path
                            className={this.state.on ? "checkMark" : "hidden"}
                            fill="#d2d6e8"
                            d="M434.539,98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618,0-14.093,2.665-19.417,7.993L169.59,247.248   l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616,0-14.087,2.662-19.417,7.992L7.994,201.852   C2.664,207.181,0,213.654,0,221.269c0,7.609,2.664,14.088,7.994,19.416l103.351,103.349l38.831,38.828   c5.327,5.332,11.8,7.994,19.414,7.994c7.611,0,14.084-2.669,19.414-7.994l38.83-38.828L434.539,137.33   c5.325-5.33,7.994-11.802,7.994-19.417C442.537,110.302,439.864,103.829,434.539,98.499z"
                        />
                    </g>
                </svg>

                Yes

            </button>



        );
    }
}
