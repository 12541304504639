import React, {Component} from 'react';
import {Hub} from 'aws-amplify';

import './css/DiabetesSmoking.css';

export default class DiabetesSmokingTab extends Component {
    getInitialState() {
        return {on: false};
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.state.on = this.props.on;

        Hub.listen("showStopSmoking1", this);
        Hub.listen("showStopSmoking2", this);
        Hub.listen("SceneReset", this);
        Hub.listen("UIReset", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    componentDidMount() {}

    componentDidUpdate() {}

    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "showStopSmoking1") {
            this.show();
        }
        else if(channel === "showStopSmoking2"){
            // logic for changing inner html
            
            const htmlMap = {
                __html: `According to your triggers you should avoid having a cigarette immediately after waking up and having your morning coffee. <br><br> Find a substitute for your cravings, like a piece of gum instead! <br><br> By cutting down by just one cigarette, you save an extra $127 a year!  <br><br> If you completely stop smoking, you will be saving over $2,500 per year!  Keep it up!`
            }

            let innerHTML = htmlMap['__html'];

            let textElem = this.refs.diabetesSmokingText;

            textElem.innerHTML = innerHTML;

        } else if (channel === "SceneReset") {
            this.hide();
        }
    }

    render() {
        return (
            <div className={this.state.on ? "absolute" : "hidden"} id="diabetesSmokingTab">

                <div id="diabetesSmokingBanner">
                    Diabetes Stop Smoking Plan
                </div>

                <div id="diabetesSmokingText" ref="diabetesSmokingText">
                    No matter what type of diabetes you have,<br/> smoking makes your diabetes harder to control.
                    <br /><br /><br />
                    If you have diabetes and you smoke, <br/>you are more likely to have serious health problems from diabetes.
                    <br /><br /><br />
                    Smokers have a 30% - 40% Higher Risk of Diabetes than nonsmoker.
                </div>
                <svg
                     transform="translate (0, 272)"
                     viewBox="0 0 2990.35 415.99"
                     overflow="visible"
                     width="80vw"
                    >
                <defs>
                    <linearGradient id="linear-gradient" y1="208" x2="2990.35" y2="208" gradientUnits="userSpaceOnUse">
                        <stop offset="0.5" stopColor="#4bcbf3" />
                        <stop offset="0.5" stopColor="#009f93" />
                        <stop offset="0.54" stopColor="#009f93" />
                    </linearGradient>
                    <linearGradient id="White_Black" x1="991.97" y1="240.99" x2="1002.94" y2="240.99" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#fff" />
                        <stop offset="1" />
                    </linearGradient>
                </defs>
                <title>Diabetes_Smoking_Graphic_V02</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <rect className="cls-1" width="2990.35" height="415.99" rx="12"/>
                        <rect className="cls-2" x="561.75" y="212.68" width="426.52" height="56.61"/>
                        <rect className="cls-3" x="452.49" y="212.68" width="141.38" height="56.61"/>
                        <rect className="cls-4" x="991.97" y="212.68" width="10.97" height="56.61"/>
                        <path className="cls-5" d="M984,189.73c2.82-6.46,6-12.74,9.08-19A30.59,30.59,0,0,0,996.48,152a29.21,29.21,0,0,0-8.14-16.44l-1.74-1.68c-.7-.69-1.42-1.36-2.08-2.08l-1.91-2.25-1.7-2.41a34.65,34.65,0,0,1-2.06-33.82,24.6,24.6,0,0,1,1.39-2.58c.51-.82,1-1.73,1.52-2.4l3-4.33,3-4.32c1-1.41,2.1-2.92,2.88-4.19a30.12,30.12,0,0,0,4.67-18.38,32.87,32.87,0,0,0-7.45-18.61,30.53,30.53,0,0,1,11.34,18.05,33.7,33.7,0,0,1,.38,11.21A32.66,32.66,0,0,1,996,78.59c-.92,1.79-1.79,3.16-2.68,4.7l-2.71,4.5-2.72,4.49c-.49.78-.8,1.41-1.19,2.1a16.54,16.54,0,0,0-1,2.08,28.57,28.57,0,0,0-1.63,18,27.77,27.77,0,0,0,3.47,8.45l1.27,1.91,1.44,1.8c.51.57,1.06,1.11,1.59,1.67l2,2a34.11,34.11,0,0,1,8.88,21.06,33,33,0,0,1-1.33,11.33l-.42,1.35-.52,1.32-.52,1.32L999.3,168l-.63,1.26c-.22.41-.48.8-.72,1.2-.5.8-1,1.61-1.49,2.3C992.41,178.43,988.37,184.17,984,189.73Z"/>
                        <path className="cls-5" d="M1006.64,197.06c2.82-6.46,6-12.74,9.08-19a30.62,30.62,0,0,0,3.37-18.74,29.24,29.24,0,0,0-8.14-16.45l-1.74-1.68c-.7-.69-1.42-1.36-2.08-2.08l-1.91-2.24-1.7-2.41a34.7,34.7,0,0,1-5.3-22.56,35,35,0,0,1,3.24-11.27,24.6,24.6,0,0,1,1.39-2.58c.51-.82,1-1.72,1.52-2.4l3-4.33,3-4.31c1-1.42,2.1-2.92,2.88-4.2a30.17,30.17,0,0,0,4.67-18.38,32.94,32.94,0,0,0-7.45-18.61,30.6,30.6,0,0,1,11.34,18.06,33.69,33.69,0,0,1,.38,11.21,32.73,32.73,0,0,1-3.49,10.86c-.92,1.78-1.79,3.16-2.69,4.7l-2.7,4.5-2.72,4.49c-.5.77-.8,1.41-1.19,2.09a16.67,16.67,0,0,0-1,2.09,28.57,28.57,0,0,0-1.63,18,27.82,27.82,0,0,0,3.47,8.44l1.27,1.92,1.44,1.79c.51.57,1.06,1.11,1.59,1.67l2,2a34.09,34.09,0,0,1,8.89,21.07A33,33,0,0,1,1024,170l-.42,1.36-.52,1.32-.52,1.31-.63,1.26-.63,1.26c-.22.41-.49.8-.72,1.2-.5.8-1,1.61-1.49,2.3C1015,185.77,1011,191.51,1006.64,197.06Z"/>
                        <path className="cls-2" d="M2268.05,171.56,2433.48,69.45s38.95.92,26.5,37.2l-108.61,66.66s39.6,38.88,23.51,67.55c0,0,26.78,35.92,11,45.76,0,0,9.55,17.07-3.28,31.65,0,0-83.57,75.16-157.31,55.38,0,0-168.72-87.69-13.12-223.14,0,0,41.64-45.71,49.06-77.75C2261.23,72.76,2333.53,46.3,2268.05,171.56Z"/>
                        <path className="cls-6" d="M2474.16,216.55a27.34,27.34,0,0,1-30.61,4.58l-.17-.09c-7.29-3.94-15.17-12.37-14.48-30.33.6-15.53,7.62-38.23,27-71.35,0,0,24.59,40.41,26.23,71.23.54,9.61-1.17,18.3-6.57,24.52C2475.14,215.61,2474.66,216.08,2474.16,216.55Z"/>
                    </g>
                </g>
            </svg>

            <div id="smokingNumber">1-800-QUIT-NOW</div>

        </div>);
    }
}
