import React from 'react';

export default class MedReminderUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on;
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }

	render() {
      return (
		<div className={this.state.on ? "" : "hidden"}>
			
		</div>
	  );
	}
}

// export default Vitals;
