import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class TemperatureTutorialImageTwo extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("TempTutorial2", this);
        Hub.listen("TempTaken", this);
        Hub.listen("TempTutorial1", this);
        Hub.listen("TempIntro", this);
        Hub.listen("UIReset", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
            return;
        }

        if(channel === "TempTutorial2" || channel === "BloodPressureIntro") {
            this.setState({on: true});
        }

        else if(channel === "TempTaken") {
            this.setState({on: false})
        }
    } 
 
    render() {
        return (
                <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} alt="Temperature Tutorial 2" src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/Temperature+2.jpg"/>		
            );
	}
}
