import React, { Component } from 'react';
import { Hub } from 'aws-amplify'; // eslint-disable-line no-unused-vars

import { GaitAppUIInit, GaitAppUIRestart, CFRSIPaginatedForm, GaitAppUITerms, BeginButton, SaveAllButton } from '../components';

export default class CFRSIGaitSceneUserInterface extends Component {
    getInitialState() {
        return {
            on: false,
            formOn: false,
            resultsReceived: false,
            beginButtonOn: true,
            saveAllButtonOn: true,
            termsOn: false,
        }
    }

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.onHubCapsule = this.onHubCapsule.bind(this);

        Hub.listen("BeginButtonClicked", this, "CFRSIGaitSceneUserInterface");
        Hub.listen("ShowBeginButton", this, "CFRSIGaitSceneUserInterface");
        Hub.listen("HideBeginButton", this, "CFRSIGaitSceneUserInterface");
        Hub.listen("Begin", this, "CFRSIGaitSceneUserInterface");
        Hub.listen("Survey", this, "CFRSIGaitSceneUserInterface");

         Hub.listen("PagesShow", this, "CFRSIGaitSceneUserInterface");
         Hub.listen("PagesHide", this, "CFRSIGaitSceneUserInterface");
         Hub.listen("TermsShow", this, "CFRSIGaitSceneUserInterface");
         Hub.listen("TermsHide", this, "CFRSIGaitSceneUserInterface")
    }

    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "BeginButtonClicked") {
            this.setState({ on: true });
        } else if (channel === "ShowBeginButton") {
            this.setState({ beginButtonOn: true })
        } else if (channel === "HideBeginButton") {
            this.setState({ beginButtonOn: false })
        } else if (channel === "Begin") {
            this.setState({
                on: true,
                formOn: true,
                beginButtonOn: true
            });
        } else if (channel === "PagesShow") {
        	this.setState({formOn: true});
        } else if (channel === "PagesHide") {
        	this.setState({formOn: false});
        } else if (channel === "TermsShow") {
            this.setState({
                formOn: false,
                termsOn: true
            })
        } else if( channel === "TermsHide") {
            this.setState({
                termsOn: false
            })
        }
    }

    render() {
        if (!(this.props.on)) return (null); //render nothing if "on" state not passed as true

        return (
            <React.Fragment>
				<GaitAppUIInit /> {/*only renders if `camName` cookie does not exist in document.cookie*/}		
				<CFRSIPaginatedForm on={this.state.formOn} />		
				<BeginButton on={this.state.beginButtonOn} />
				<GaitAppUITerms on={this.state.termsOn} />
				{/*<GaitAppUIRestart />*/}
				{/*<SaveAllButton on={this.state.saveAllButtonOn} />*/}

			</React.Fragment>

        );

    }
}