import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class PulseOxTutorialImageTwo extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("PulseOxTutorial2", this);
        Hub.listen("PulseOxTaken", this);
        Hub.listen("PulseOxTutorial1", this);
        Hub.listen("PulseOxIntro", this);
        Hub.listen("UIReset", this);
        Hub.listen("ReadyBtn", this);
        Hub.listen("HideReady", this);
        Hub.listen("ready", this);
                Hub.listen("readybtn", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
            return;
        }

        if(channel === "PulseOxTutorial2" || channel === "PulseOxIntro") {
            this.setState({on: true});
        }

        else if(channel === "PulseOxTaken" || channel === "HideReady" || channel === "ReadyBtn" || channel === "ready" || channel === "readybtn") {
            this.setState({on: false})
        }
    }

    render() {
            return (
                    <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} alt="Pulse Ox Tutorial 2" src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/PulseOx+2.jpg"/>
		);
	}
}
