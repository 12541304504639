import React from 'react';
import { Hub } from 'aws-amplify';
import mealIdeas2_SVG from './svg/mealIdeas2_SVG.svg';  


export default class MealIdeasTwo extends React.Component {
    getInitialState() {
        return {
            on: false,
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        Hub.listen("UIReset", this);
        Hub.listen("showMeals2", this);
        Hub.listen("hideMeals2", this);
    }

    toggle() {
        this.setState({ on: !this.state.on });
    }

    show() {
        this.setState({ on: true });
    }


    hide() {
        this.setState({ on: false });
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "showMeals2") {
            this.setState({ on: true });
        } else if (channel === "hideMeals2") {
            this.setState({ on: false });
        }
    }

    render() {
        return (
            <div id="summary-diagram-container" className={this.state.on ? "diagram" : "hidden"}>
          <div id="summaryBanner">
            <div id="summary-container progress" className="summary-header">
              <div id="nutritionPlan-header-text">Nutrition Plan</div>
            </div>
          </div>
          <div className="body">
            <div className="svg-container">
            <img src={mealIdeas2_SVG}  alt=""/>
        </div>    
          </div>
        </div>
        );
    }
}