import React, {Component} from 'react';
import { Hub } from 'aws-amplify';
import { SummaryButtons, AchievementContainer, UtterancesList} from '../components';

export default class SummaryDiagram extends Component {
	getInitialState() {
		return {
			on: false, 
			toggleButtonOn: false
		} 
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();


        Hub.listen("AneltoReceived", this);

        Hub.listen("toggleSummary", this, "SummaryDiagram");
        Hub.listen("VitalsDone", this);
        Hub.listen("VitalsTaken", this);
        Hub.listen("ShowSummary", this);
        Hub.listen("summaryDiagram", this);
        Hub.listen("Buttons", this); //think this is meant to show the summary diagram

        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        // this.state.on = this.props.on;
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }
    
    toggleSummary(event) {
    	event.preventDefault();

      	this.setState({on: !(this.state.on), toggleButtonOn: !(this.state.toggleButtonOn)})
    }

    show() {
        this.setState({on: true, toggleButtonOn:true});
    }

    hide() {
        this.setState({on: false, toggleButtonOn: false});
    }


    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        console.log("in SummaryDiagram.onHubCapsule!");

        if(channel === "AneltoReceived") {
            console.log("in SummaryDiagram.jsx, AneltoReceived event payload: ", JSON.stringify(payload, null, 2));
        }

        else if(channel === "toggleSummary") {
	      this.toggleSummary();
	    } 

        else if (channel === "VitalsDone") {
            this.setState({on: false});
        }

//took out channel "buttons" becauce it was playing too early in asthma vitals scene
        else if (channel === "VitalsTaken" || channel === "summaryDiagram" || channel === "ShowSummary") {
            this.setState({on: true});
        }
    }	

    updateVitalResultDivs(results) {
        // let systolic = results.systolic;
        // let diastolic = results.diastolic;
        // let bpm = results.heartRate;
        // let temp = results.temperature;
        // let oxygen = results.oxygen;
        // let weight = results.weight;
        /*
        ctx.worldData.weightValueDiv.innerHTML = weight.toString() + " lbs";
        ctx.worldData.weightValueSummaryDiv.innerHTML = weight.toString() + " lbs";
        
        ctx.worldData.oxygenValueDiv.innerHTML = oxygen.toString() + "%";   
        ctx.worldData.oxygenValueSummaryDiv.innerHTML = oxygen.toString() + "%";
        
        ctx.worldData.temperatureValueDiv.innerHTML = temp.toString() + String.fromCharCode(176); //degree sign
        ctx.worldData.temperatureValueSummaryDiv.innerHTML = temp.toString() + String.fromCharCode(176);
        
        ctx.worldData.bpValueDiv.innerHTML = systolic.toString() + "/" + diastolic.toString();
        ctx.worldData.bpValueSummaryDiv.innerHTML = systolic.toString() + "/" + diastolic.toString();
        
        ctx.worldData.hrValueDiv.innerHTML = bpm.toString() + " bpm";
        ctx.worldData.hrValueSummaryDiv.innerHTML = bpm.toString() + " bpm";
        */
    }


	render() {
		return (
		  <div id="summary-container"> 
			<div id="summary-diagram-container" className={this.state.on ? "diagram" : "hidden"}>
				<AchievementContainer />
                <UtterancesList />
				<SummaryButtons />
			</div>
		  </div>
		);
	}
}