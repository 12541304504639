import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class NutritionFinishedButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on;

        Hub.listen("SceneReset", this);
        Hub.listen("showNutritionFinished", this);
        Hub.listen("NutritionDone", this);
        Hub.listen("toggleFinishedBtn", this);
        Hub.listen("UIReset", this);

        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }



    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      window.amplifySceneLoader.emit("post_to_lex", "NutritionDone");
    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "showNutritionFinished") {
        	this.setState({on: true});
        }
        
        else if(channel === "NutritionDone" || channel === "SceneReset") {
        	this.setState({on: false});
        }
        
        else if(channel === "toggleFinishedBtn") {
        	this.setState({on: !(this.state.on)});
        }
    }


    render() {
        return (
            <button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnnutritionfinished">Finished</button>
        );
    }
}