import React from 'react';
import { Hub } from 'aws-amplify';

export default class GaitSceneUserInterfaceDiabetes extends React.Component {
	getInitialState() {
		return {
			on: true,
            //listeners: ["showGaitUserInterface", "toggleGaitUserInterface"]

		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on;

        // Hub.listen("showAsthmaVitalsUserInterface", this);
        // Hub.listen("toggleAsthmaVitalsUserInterface", this);
        Hub.listen("playGaitSceneDiabetes", this);
        Hub.listen("toggleGaitSceneDiabetesUserInterface", this);
        Hub.listen("playGaitSceneHeartDisease", this);
        Hub.listen("toggleGaitSceneHeartDiseaseUserInterface", this);
        Hub.listen("playGaitScene", this);
        Hub.listen("toggleGaitSceneUserInterface", this);
        
        //this.state.listeners.forEach( (listener) => {
        //    Hub.listen(listener, this);
        //});
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    toggle() {
        this.setState({on: !this.state.on});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
        console.log("channel: " + channel)
        
        if(channel === "UIReset") {
           this.reset();
        }
        
        if(channel === "ShowReady") {
           this.show();
        }

        if(channel === "HideReady") {
          this.hide();
        }

        if(channel === "toggleReady") {
          this.setState({on: !(this.state.on)});
        }
        if(channel === "playGaitSceneDiabetes") {
            this.setState({on: !(this.state.on)});
        }

    }

	render() { 
      return (
		<div className={this.state.on ? "" : "hidden"}>

		</div>
	  );
	}
}