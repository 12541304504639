import { Hub } from 'aws-amplify';
import React from 'react';
import { Client } from 'boardgame.io/react';
import { Game, INVALID_MOVE } from 'boardgame.io/core';
import _ from 'lodash';

const NUM_TILES = 5 * 5; //5x5 board
const LETTERS = ['B', 'I', 'N', 'G', 'O'];
let NUM_RANGE = [];

for (var i = 1; i <= 75; i++) {
    NUM_RANGE.push(i);
}

function generateLetter() {
    return _.sample(LETTERS);
}

function generateNumber(letter, first, last) {
    return _.random(first, last);
}




function generateCombination() {
    let letter = generateLetter();
    let number;

    let colAValues = [];
    let colBValues = [];
    let colCValues = [];
    let colDValues = [];
    let colEValues = [];
    switch (letter) {
        case 'B':
            number = _.random(1, 15);

            if (colAValues.length === 0) {
                colAValues.push(number);
                break;
            }

            while (number in colAValues) {
                number = _.random(1, 15);
            }

            console.log('B generated, number:', number);
            colAValues.push(number);
            break;
        case 'I':
            number = _.random(16, 30);

            if (colBValues.length === 0) {
                colBValues.push(number);
                break;
            }
            while (number in colBValues) {
                number = _.random(16, 30);
            }
            console.log('I generated, number:', number);
            colBValues.push(number);
            break;
        case 'N':
            number = _.random(31, 45);

            if (colCValues.length === 0) {
                colCValues.push(number);
                break;
            }

            while (number in colCValues) {
                number = _.random(31, 45);
            }
            colCValues.push(number);
            console.log('N generated, number:', number);
            break;
        case 'G':
            number = _.random(46, 60);

            if (colDValues.length === 0) {
                colDValues.push(number);
                break;
            }
            while (number in colDValues) {
                number = _.random(46, 60);
            }
            colDValues.push(number);
            console.log('G generated, number:', number);
            break;
        case 'O':
            number = _.random(61, 75);

            if (colEValues.length === 0) {
                colEValues.push(number);
                break;
            }
            while (number in colEValues) {
                number = _.random(61, 75);
            }
            colEValues.push(number);
            console.log('O generated, number:', number);
            break;

        default:
            console.log("letter is not in BINGO, wtf?");
            break;
    }

    return { letter: letter, number: number }
}

function IsVictory(cells) {
    var x, y;
    var didWin = false;

    let leftDiagonal = [0, 6, 12, 18, 24];
    let rightDiagonal = [4, 8, 12, 16, 20];
    let colA = [0, 5, 10, 15, 20];
    let colB = [1, 6, 11, 16, 21];
    let colC = [2, 7, 12, 17, 22];
    let colD = [3, 8, 13, 18, 23];
    let colE = [4, 9, 14, 19, 24];
    let row1 = [0, 1, 2, 3, 4];
    let row2 = [5, 6, 7, 8, 9];
    let row3 = [10, 11, 12, 13, 14];
    let row4 = [15, 16, 17, 18, 19];
    let row5 = [20, 21, 22, 23, 24];

    let win = true;
    leftDiagonal.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won

    win = true;
    rightDiagonal.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    colA.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    colB.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    colC.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    colD.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    colE.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    row1.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    row2.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    row3.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    row4.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won


    win = true;
    row5.forEach((i) => {
        if (cells[i] !== 'X') win = false;
    });

    if (win) return true; //if either diagonals are fully filled, the game is won

    return false;
}

function createRange(first, last) {
    var i;
    let lst = [];
    for (i = first; i <= last; i++) {
        lst.push(i);
    }
}
const BingoGame = Game({
    setup: () => {
        let G = { tiles: Array(NUM_TILES).fill(null) }

        //new approach: get a value from potential values, remove the value, repeat 5 times per column
        let colAPotentialValues = _.range(1, 15);
        let colBPotentialValues = _.range(16, 30);
        let colCPotentialValues = _.range(31, 45);
        let colDPotentialValues = _.range(46, 60);
        let colEPotentialValues = _.range(61, 75);

        let colA = [0, 5, 10, 15, 20];
        let colB = [1, 6, 11, 16, 21];
        let colC = [2, 7, 12, 17, 22];
        let colD = [3, 8, 13, 18, 23];
        let colE = [4, 9, 14, 19, 24];

        colA.forEach((i) => {
            let num = _.sample(colAPotentialValues);
            colAPotentialValues = colAPotentialValues.filter(e => e !== num);
            G.tiles[i] = num;
        });

        colB.forEach((i) => {
            let num = _.sample(colBPotentialValues);
            colBPotentialValues = colBPotentialValues.filter(e => e !== num);
            G.tiles[i] = num;
        });

        colC.forEach((i) => {
            let num = _.sample(colCPotentialValues);
            colCPotentialValues = colCPotentialValues.filter(e => e !== num);
            G.tiles[i] = num;
        });

        colD.forEach((i) => {
            let num = _.sample(colDPotentialValues);
            colDPotentialValues = colDPotentialValues.filter(e => e !== num);
            G.tiles[i] = num;
        });

        colE.forEach((i) => {
            let num = _.sample(colEPotentialValues);
            colEPotentialValues = colEPotentialValues.filter(e => e !== num);
            G.tiles[i] = num;
        });

        G.tiles[12] = "X"; //middle square is free

        G.currentCombination = generateCombination(); //get the letter/number combination
        G.currentLetter = G.currentCombination.letter; //store them separately for convenience
        G.currentNumber = G.currentCombination.number;

        return G;
    },

    moves: {
        clickCell(G, ctx, id) {
            console.log("in clickCell, id:", id);
            console.log(`in clickCell, G.currentLetter = ${G.currentLetter}`);
            if (G.tiles[id] === "X") {
                console.log("tile already has been claimed");
                return INVALID_MOVE;
            }
            if (G.tiles[id] !== G.currentNumber) {
                console.log("tile does not contain the same number as the current combination");
                return INVALID_MOVE;
            }

            G.tiles[id] = 'X';

            return G;
        },


        drawCombo(G, ctx) {
            G.currentCombination = generateCombination(); //get the letter/number combination
            G.currentLetter = G.currentCombination.letter; //store them separately for convenience
            G.currentNumber = G.currentCombination.number;
        },
    },


    flow: {
        endGameIf: (G, ctx) => {
            if (IsVictory(G.tiles)) {
                return { winner: ctx.currentPlayer }
            }
        },

        // selectCombination: (G, ctx) => {

        // }
    }
});

class BingoBoard extends React.Component {
    async onClick(id) {
        console.log("tile clicked, id:", id);
        console.log("id isActive:", this.isActive(id));

        if (!this.isActive(id)) return;

        // if (this.isActive(id)) {
        let res = this.props.moves.clickCell(id);

        console.log("res: ", res);

        if (res !== INVALID_MOVE) {
            this.props.events.endTurn();
            this.props.moves.drawCombo();
        }
    }

    onDrawClick() {
        this.props.moves.drawCombo();
    }

    isActive(id) {
        if (!this.props.isActive) return false;
        if (this.props.G.tiles[id] !== 'X') return false;
        return true;
    }

    getInitialState() {
        return {
            on: false
        }
    }

    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        Hub.dispatch("ToggleSceneSelectMenu");
    }

    render() {
        if (!(this.props.on)) return (null);

        let winner = '';
        if (this.props.ctx.gameover) {
            winner =
                this.props.ctx.gameover.winner !== undefined ? (
                    <div id="winner">BINGO!</div>
                ) : (
                    <div id="winner">Draw!</div>
                );
        }

        const cellStyle = {
            border: '1px solid #555',
            padding: "2.5vh 2vw",
            width: '2vw',
            height: '2.5vh',
            lineHeight: '50px',
            textAlign: 'center',
        };

        let tbody = [];
        for (let i = 0; i < 5; i++) {
            let cells = [];
            for (let j = 0; j < 5; j++) {
                const id = 5 * i + j;
                cells.push(
                    <td style={cellStyle} key={id} onClick={() => this.onClick(id)}>
            {this.props.G.tiles[id]}
          </td>
                );
            }
            tbody.push(<tr key={i}>{cells}</tr>);
        }

        return (
            <div className="bingo-boredo" style={{display:"flex", flexDirection:"column",position: "absolute", left:"50vw", top: "17vh", zIndex: "10000"}}>
      	<div style={{fontSize: "3vh", fontWeight: "bold"}} id="currentCombination">
      		{this.props.G.currentLetter}/{this.props.G.currentNumber}
      	</div>
	  	<button className="btn-ecg" style={{zIndex:"10000", padding: "auto", margin:"auto"}} id="drawCombination" onClick={() => this.onDrawClick()}>
	  		Draw Again
	  	</button> 

	  	<table style={{left:"45vw", top: "20vh"}} id="board">
          <thead>
          	<tr>
          		<th>B</th>
          		<th>I</th>
          		<th>N</th>
          		<th>G</th>
          		<th>O</th>
          	</tr>
          </thead>
          <tbody>{tbody}</tbody>
        </table>
        {winner}
      </div>
        );
    }

}

const AppBingoGame = Client({
    game: BingoGame,
    board: BingoBoard,
    debug: false,
    // debug: true, 
    numPlayers: 1,
});

export default AppBingoGame;