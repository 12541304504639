import React from "react";
import { Auth, Logger } from 'aws-amplify';
// import Auth from '@aws-amplify/auth';
import { SignIn } from "aws-amplify-react";

const logger = new Logger("LogIn");

class LogIn extends SignIn {
  constructor(props) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];

    this.state = {};
  }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) return;

        // const { hide = [] } = this.props;
        if (this.props.authState === 'signIn') {
            this.signIn();
        }
    }

    async signIn() {
        const { username, password } = this.inputs;
        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({loading: true});
        try {
            const user = await Auth.signIn(username, password);
            logger.debug(user);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                logger.debug('confirm user with ' + user.challengeName);
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                logger.debug('the user is not confirmed');
                this.changeState('confirmSignUp', {username});
            } else if (err.code === 'PasswordResetRequiredException') {
                logger.debug('the user requires a new password');
                this.changeState('forgotPassword', {username});
            } else {
                this.error(err);
            }
        } finally {
            this.setState({loading: false})
        }
    }

  showComponent(theme) {
    // const { authState, hide = [], federated, onStateChange, onAuthEvent, override=[] } = this.props;
    // if (hide && hide.includes(SignIn)) { return null; }
    /*const hideSignUp = !override.includes('SignUp') && hide.some(component => component === SignUp);*/
    /*const hideForgotPassword = !override.includes('ForgotPassword') && hide.some(component => component === ForgotPassword);*/

    return (
      <div className="center text-center">
        <div className="form-signin"> 
          <img height="180" src={require('../assets/addisoncare.jpg')} alt=""/>
          <h1 className="h3 mb-3 font-weight-normal">Please Sign In</h1>
          <label htmlFor="username" className="sr-only">Username</label>
          <input
            className="form-control" 
            id="username"
            key="username"
            name="username"
            onChange={this.handleInputChange}
            type="username"
            placeholder="Username"
          />
          <label htmlFor="password" className="sr-only">Password</label>
          <input 
            className="form-control"
            id="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
            type="password"
            placeholder="Password"
          />
          <button className="signin-btn" onClick={() => this.signIn()}>
            Sign in
          </button>
        </div>
      </div>
    );
  }
}

export default LogIn;