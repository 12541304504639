import React, {Component} from 'react';
import { Hub } from 'aws-amplify';
// import AmplifySceneLoader from '../AmplifySceneLoader';

import '../addison.css';

let connectingTo = require('./svg/connectingTo.png');


export default class AppHelpScreen extends Component {
  getInitialState() {
    return {
      on: false
    }
  }

  constructor(props) { 
    super(props);
    this.state = this.getInitialState();

    Hub.listen("loadHelpScreen", this);
    console.log("checkListener");
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.reset = this.reset.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);

  } //end ctor  


  componentDidMount() {
  }

  componentDidUpdate() {
  }

  toggle() {
    this.setState({on: !this.state.on }); //toggle 
  }

  hide() {
    this.setState({on: false});
  }

  show() {
    this.setState({on: true});
  }

  reset() {
    this.setState(this.getInitialState());
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    
    if(channel === "loadHelpScreen") {
      this.show();
    }

  }


	render() {
		return (
      <div style={{zIndex: "9000"}} className={this.state.on ? "helpScreenBackground" : "hidden"} id="helpScreenBackground">
          <div className="helpScreenTitle" id="helpScreenTitle">
              Attention
          </div>
          <div className="helpScreenText" id="helpScreenText">
                  <img src={connectingTo}  alt=""/>
          </div> 
      </div>

		);
	}
}