//localUtils.js

import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';


function getCookieInformation( name ) {
    let cookieItems = decodeURIComponent( document.cookie )
        .split( ';' )
    for ( let i = 0; i < cookieItems.length; i++ ) {
        let index = cookieItems[ i ].search( name + '=' )
        if ( index > -1 )
            return cookieItems[ i ].substr( index + name.length + 1 ) // +1 for the '='
    }
    return null
}

/**
 * [attachIotPolicy description]
 * @return {[type]} [description]
 */
async function attachIotPolicy() {
    try {
		var iot = new AWS.Iot();
        const policyName = 'ernest_health_iot_policy';
        const credentials = Auth.essentialCredentials();
        const target = credentials._identityId;

        const { policies } = await iot.listAttachedPolicies( { target } ).promise();

        if ( !policies.find( policy => policy.policyName === policyName ) ) {
            await iot.attachPolicy( { policyName, target } ).promise();
        }


    } catch ( e ) {
        // console.log(`error describing thing with thingName of ${gCameraName}, error: ${JSON.stringify(e, null, 2)}`);
        console.log( "Error stack", JSON.stringify( e.stack, null, 2 ) ); //original error message
    }
}

export { getCookieInformation, attachIotPolicy };