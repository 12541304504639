import React, {Component} from 'react';
import { Hub } from 'aws-amplify';

let TopSVG = require('./svg/appPendantTopSVG.png');
let AttentionSVG = require('./svg/appPendantAttentionSVG.png');
let ProHealthSVG = require('./svg/appPendantProHealthSVG.png');

export default class AppPendantScreen extends Component {
  getInitialState() {
    return {
      on: false
    }
  }

  constructor(props) { 
    super(props);
    this.state = this.getInitialState();

    Hub.listen("RapidEventReceived", this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.reset = this.reset.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);
  } //end ctor  


  componentDidMount() {
  }

  componentDidUpdate() {
  }

  toggle() {
    this.setState({on: !this.state.on }); //toggle 
  }

  hide() {
    this.setState({on: false});
  }

  show() {
    this.setState({on: true});
  }

  reset() {
    this.setState(this.getInitialState());
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    
    if(channel === "RapidEventReceived") {
      this.show();
    }

  }


	render() { 
		return (

      <div style={{zIndex: "9000"}} className={this.state.on ? "helpScreenBackground" : "hidden"} id="helpScreenBackground">
          <div className="helpScreenTitle" id="helpScreenTitle">

              <img src={TopSVG} width="75" height="90"  alt=""/>
              {/*<SVGInline svg={TopSVG} />*/}
              Attention

              <img src={AttentionSVG} width="75" height="90"  alt=""/>
              {/*<SVGInline svg={AttentionSVG} />*/}
          </div>
          <div className="helpScreenText" id="helpScreenText">
              Electronic Caregiver Support
              <br/>
              is attempting to contact you.
              <br/>
              <div id="proHealthSVGLarge">
                <img src={ProHealthSVG}  alt=""/>
                {/*<SVGInline svg={ProHealthSVG} />*/}
              </div>
              Please check your device.
          </div> 
      </div>

		);
	}
}