import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class GreatJobTab extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("GreatJob", this);
        Hub.listen("SceneReset", this);
        Hub.listen("UIReset", this);
        Hub.listen("btndone", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
           this.reset();
        }

        else if(channel === "GreatJob" || channel === "btndone") {
           this.show();
        }

        else if(channel === "SceneReset" ) {
          this.hide();
        }
    }


    render() {
        return (

            <div id="TakenScreen" className={this.state.on ? "TakenScreen" : "hidden"}>

                <div id="GreatJob" className={this.state.on ? "GreatJob" : "hidden"}>

                        <svg
                              viewBox="0 0 504.492 504.492"
                              width="5vw"
                              height="5vw"
                              overflow="visible"
                              >

                              <g transform="translate(-100,90)">
                              <path
                              className="ribbonRight"
                              style={{fill:"#8CDDF7"}}
                              d="M308.567,161.452l-152.025,68.923l122.88,270.966l66.166-55.926l85.465-12.603  C431.053,432.812,308.567,161.452,308.567,161.452z"/>

                              <path
                              className="leftRibbon"
                              style={{fill:"#4BCBF3"}}
                              d="M195.927,164.209l152.025,68.923l-122.88,271.36l-66.166-56.32l-85.858-12.603  C73.047,435.569,195.927,164.209,195.927,164.209z"/>

                              <path
                              className={this.state.on ? "medalOuter" : "hidden"}
                              style={{fill:"#EFC75E"}}
                              d="M449.17,197.292c0,14.572-20.874,26.782-24.025,40.566c-3.151,14.178,10.634,34.265,4.726,47.262  c-6.302,12.997-30.326,14.572-38.991,25.6s-4.726,35.446-15.754,44.505s-33.083-0.394-45.686,5.908s-19.298,29.932-33.083,33.083  c-13.391,3.151-29.538-14.966-43.717-14.966c-14.178,0-30.326,18.117-43.717,14.966c-13.785-3.151-20.48-26.782-33.083-33.083  c-12.603-6.302-35.052,3.151-45.686-5.908c-11.028-9.058-7.089-33.477-15.754-44.505s-32.689-12.603-38.991-25.6  c-5.908-12.603,7.877-33.083,4.726-47.262c-3.938-13.785-24.812-25.994-24.812-40.566s20.874-26.782,24.025-40.566  c3.151-14.178-10.634-34.265-4.726-47.262c6.302-12.997,30.326-14.572,38.991-25.6s4.726-35.446,15.754-44.505  s33.083,0.394,45.686-5.908c12.603-6.302,19.298-29.932,33.083-33.083c13.391-3.151,29.538,14.966,43.717,14.966  c14.178,0,30.326-18.117,43.717-14.966c13.785,3.151,20.48,26.782,33.083,33.083c12.603,6.302,35.052-3.151,45.686,5.908  c11.028,9.058,7.089,33.477,15.754,44.505s32.689,12.603,38.991,25.6c5.908,12.603-7.877,33.083-4.726,47.262  C428.296,170.511,449.17,182.72,449.17,197.292z"/>

                              <path
                              className="medalInner"
                              style={{fill:"#D7B354"}}
                              d="M252.247,118.523c-43.717,0-78.769,35.446-78.769,78.769s35.446,78.769,78.769,78.769  c43.717,0,78.769-35.446,78.769-78.769S295.963,118.523,252.247,118.523z M252.247,232.738c-19.692,0-35.446-15.754-35.446-35.446  s15.754-35.446,35.446-35.446s35.446,15.754,35.446,35.446S271.939,232.738,252.247,232.738z M252.247,55.507  c-78.375,0-141.785,63.409-141.785,141.785s63.409,141.785,141.785,141.785s141.784-63.41,141.784-141.785  S330.622,55.507,252.247,55.507z M252.247,315.446c-65.378,0-118.154-52.775-118.154-118.154S186.868,79.138,252.247,79.138  s118.154,52.775,118.154,118.154S317.625,315.446,252.247,315.446z"/>                 </g>
                      </svg>


                Great job!


                        <svg
                              viewBox="0 0 504.492 504.492"
                              width="5vw"
                              height="5vw"
                              overflow="visible"
                              >

                              <g transform="translate(100,90)">
                              <path
                              className={this.state.on ? "leftRibbon" : "hidden"}
                              style={{fill:"#8CDDF7"}}
                              d="M195.927,164.209l152.025,68.923l-122.88,271.36l-66.166-56.32l-85.858-12.603  C73.047,435.569,195.927,164.209,195.927,164.209z"/>

                              <path
                              className={this.state.on ? "ribbonRight" : "hidden"}
                              style={{fill:"#4BCBF3"}}
                              d="M308.567,161.452l-152.025,68.923l122.88,270.966l66.166-55.926l85.465-12.603  C431.053,432.812,308.567,161.452,308.567,161.452z"/>

                              <path
                              className={this.state.on ? "medalOuter" : "hidden"}
                              style={{fill:"#EFC75E"}}
                              d="M449.17,197.292c0,14.572-20.874,26.782-24.025,40.566c-3.151,14.178,10.634,34.265,4.726,47.262  c-6.302,12.997-30.326,14.572-38.991,25.6s-4.726,35.446-15.754,44.505s-33.083-0.394-45.686,5.908s-19.298,29.932-33.083,33.083  c-13.391,3.151-29.538-14.966-43.717-14.966c-14.178,0-30.326,18.117-43.717,14.966c-13.785-3.151-20.48-26.782-33.083-33.083  c-12.603-6.302-35.052,3.151-45.686-5.908c-11.028-9.058-7.089-33.477-15.754-44.505s-32.689-12.603-38.991-25.6  c-5.908-12.603,7.877-33.083,4.726-47.262c-3.938-13.785-24.812-25.994-24.812-40.566s20.874-26.782,24.025-40.566  c3.151-14.178-10.634-34.265-4.726-47.262c6.302-12.997,30.326-14.572,38.991-25.6s4.726-35.446,15.754-44.505  s33.083,0.394,45.686-5.908c12.603-6.302,19.298-29.932,33.083-33.083c13.391-3.151,29.538,14.966,43.717,14.966  c14.178,0,30.326-18.117,43.717-14.966c13.785,3.151,20.48,26.782,33.083,33.083c12.603,6.302,35.052-3.151,45.686,5.908  c11.028,9.058,7.089,33.477,15.754,44.505s32.689,12.603,38.991,25.6c5.908,12.603-7.877,33.083-4.726,47.262  C428.296,170.511,449.17,182.72,449.17,197.292z"/>

                              <path
                              className={this.state.on ? "medalInner" : "hidden"}
                              style={{fill:"#D7B354"}}
                              d="M252.247,118.523c-43.717,0-78.769,35.446-78.769,78.769s35.446,78.769,78.769,78.769  c43.717,0,78.769-35.446,78.769-78.769S295.963,118.523,252.247,118.523z M252.247,232.738c-19.692,0-35.446-15.754-35.446-35.446  s15.754-35.446,35.446-35.446s35.446,15.754,35.446,35.446S271.939,232.738,252.247,232.738z M252.247,55.507  c-78.375,0-141.785,63.409-141.785,141.785s63.409,141.785,141.785,141.785s141.784-63.41,141.784-141.785  S330.622,55.507,252.247,55.507z M252.247,315.446c-65.378,0-118.154-52.775-118.154-118.154S186.868,79.138,252.247,79.138  s118.154,52.775,118.154,118.154S317.625,315.446,252.247,315.446z"/></g>
                        </svg>
                </div>



            <div className={this.state.on ? "helpContainer" : "hidden"}>
                <div className={this.state.on ? "progress-segment" : "hidden"}>
                  <div className={this.state.on ? "item segOne" : "hidden"}    id="segOne"></div>
                  <div className={this.state.on ? "item segTwo" : "hidden"}    id="segTwo"></div>
                  <div className={this.state.on ? "item segThree" : "hidden"}  id="segThree"></div>
                  <div className={this.state.on ? "item segFour" : "hidden"}   id="segFour"></div>
                  <div className={this.state.on ? "item" : "hidden"}   id="segFive"></div>
                  <div className={this.state.on ? "item" : "hidden"}    id="segSix"></div>
                  <div className={this.state.on ? "item" : "hidden"}  id="segSeven"></div>
              </div>
            </div>



            You've taken your medication<br id="beforeNumber"/>

                <div id="Streak" className={this.state.on ? "Streak" : "hidden"}>30</div>

            days in a row!

        </div>

        );
    }
}
