/* eslint-disable import/no-extraneous-dependencies */
/*
  issue with react-hot-loader
  eventhough those 2 dependencies are only used in development
  eslint has no way to tell that and outputs an error
*/



// react dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
// import AmplifySceneLoader from './AmplifySceneLoader';
import './addison.css';


var AWS = require('aws-sdk');

function init() {
  window.LOG_LEVEL = 'DEBUG';

  new AWS.IotData({endpoint: 'my.host.tld'});
  new AWS.Polly();
  new AWS.LexRuntime();
  new AWS.Iot();

}

init();

// window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();

const root = document.getElementById('root');

const render = (Component) => {
  ReactDOM.render(
      <Component />,
    root
  );
};

render(App); 

if (module.hot) {
 module.hot.accept('./App', () => { render(App); });
}
