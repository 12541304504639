import { XR, Hub } from 'aws-amplify';

const introConfig = require('./config/CES_IntroScene.json'); // This file will be generated by the Sumerian AWS Console
const loaderSceneConfig = require('./config/CES_LoaderScene.json');

const sceneConfigs = {
	introScene: {
		name: "introScene",
		config: introConfig,
		domId: "intro-dom-id",
		region: 'us-east-1',
		events: {
			load: ["loadIntroScene"],
			play: ["playIntroScene"],
			sumerianplay: [""],
			sumerianLoad: [""]
		}
	}, //end introScene
	helpScene: {
		name: "helpScene",
		events: {
			load: ['loadHelpScene'],
			play: ['playHelpScene', 'RR_Scene']
		}
	},
	copingScene: {
		name: "copingScene",

		events: {
			load: ["loadCopingScene"],
			play: ["playCopingScene"],
		},
	},
	activityMonitoringScene: {
		name: "activityMonitoringScene",

		events: {
			load: ["loadActivityMonitoringScene"],
			play: ["playActivityMonitoringScene"],
		},
	},
	nutritionScene: {
		name: "nutritionScene",
		events: {
			load: ["loadNutritionScene"],
			play: ["playNutritionScene"],
		},
	},
	gaitSceneCFRSI: {
		name: "gaitSceneCFRSI",
		events: {
			load: ["loadGaitSceneCFRSI"],
			play: ["playGaitSceneCFRSI"],
		},
	},
	gaitSceneCFRSI_Maria: {
		name: "gaitSceneCFRSI_Maria",
		events: {
			load: ["loadGaitSceneCFRSI_Maria"],
			play: ["playGaitSceneCFRSI_Maria"],
		},
	},
	gaitSceneHeartDisease: {
		name: "gaitSceneHeartDisease",
		events: {
			load: ["loadGaitSceneHeartDisease"],
			play: ["playGaitSceneHeartDisease"],
		},
	},
	gaitSceneDiabetes: {
		name: "gaitSceneDiabetes",
		events: {
			load: ["loadGaitSceneDiabetes"],
			play: ["playGaitSceneDiabetes"],
		},
	},
	medSceneAsthma: {
		name: "medSceneAsthma",
		events: {
			load: ["loadMedSceneAsthma"],
			play: ["playMedSceneAsthma"],
		}
	}, //end medScene
	medSceneDiabetes: {
		name: "medSceneDiabetes",
		events: {
			load: ["loadMedSceneDiabetes"],
			play: ["playMedSceneDiabetes"],
			sumerianplay: ["Diabetes_Meds"],
			sumerianLoad: ["Diabetes_Meds"]
		}
	}, //end medScene
	medSceneHeartDisease: {
		name: "medSceneHeartDisease",
		events: {
			load: ["loadMedSceneHeartDisease"],
			play: ["playMedSceneHeartDisease"],
		}
	}, //end medScene
	vitalsSceneAsthma: {
		name: "vitalsSceneAsthma",
		events: {
			load: ["loadVitalsSceneAsthma"],
			play: ["playVitalsSceneAsthma"],
		}
	}, //end vitalsScene
	vitalsSceneDiabetes: {
		name: "vitalsSceneDiabetes",
		events: {
			load: ["loadVitalsSceneDiabetes"],
			play: ["playVitalsSceneDiabetes"],
			sumerianplay: ["Diabetes_Vitals"],
			sumerianLoad: "Diabetes_Vitals"
		}
	}, //end vitalsScene
	vitalsSceneHeartDisease: {
		name: "vitalsSceneHeartDisease",
		events: {
			load: ["loadVitalsSceneHeartDisease"],
			play: ["playVitalsSceneHeartDisease"],
			sumerianplay: [""],
			sumerianLoad: [""]
		}
	}, //end vitalsScene
}; //end sceneConfigs



export default class AmplifySceneLoader {
	constructor() {
		this.state = {
			sceneConfigs: sceneConfigs,
			currentScene: sceneConfigs.introScene,
			loaded: false,
			sceneController: null
		};
console.log("Reached scene loader");
		this.sceneConfigs = sceneConfigs;
		this.currentScene = sceneConfigs.introScene;


		/*** list of emits from Sumerian that need to be re-broadcast as Hub dispatches
		*
		* https://docs.google.com/spreadsheets/d/19V55p9QOYEGMXZtGLJlMYJ4qBSUauDJEXHqqYrRVcHM/
		*
		**/
		this.sumerianEmits = [

			"showYesNoBtn",
			"hideYesNoBtn",
			"HideYes",
			"HideNo",
			"ShowYes",
			"ShowNo",
			"YesBtn",								//Progresses the scene down the Yes path.  Hides Yes and NotYet Btn
			"NoBtn",
			"ShowSnooze",
			"HideSnooze",
			"snooze",								//Progresses the scene down the No path.  Hides Yes and NotYet Btn
			"helpbtn",								//Pulls up the HTML_Help_Tab
			"closeHelp",							//Closes the HTML_Help_Tab
			"btndone",								//Progresses the scene to the GreatJob section.  Hides Done Btn
			"RRBtn",
			"CSBtn",
			"AddisonBtn",
			"readybtn",
			"ShowReady",
			"ready",
			"ReadyBtn",
			"yes",
			"no",
			"not yet",
			"finished",
			"loadHelpScreen",
			
			"ListDone",
			"ShowFoundIt",
			"ShowFoundItBtn",
			"nextMedUI",
			"MissedMedList",						//Displays nextMed screen
			"showHulaHoop",							//Display the HulaHoop Video
			"hulaHoopDone",
			"UpdateMedList",
			"LookUser",
			"GreatJob",
			"StartTimer",
			"ActiveAlarm",
			"LookUI",
			"HD_StopSmoking_TextChange",
			"Diabetes_StopSmoking_TextChange",
			"nextMedUI",  							// {medName:"Advil",medTime: "8:00 am",medDate: "Tomorrow"}
			"CountDownTimer", 						//{seconds: 10, minutes: 00}
			"UpdateMedList", 						// {<someData>}
			"PulseOxTutorial",
			"BloodPressureTutorial",
			"TempTutorial",


			"GlucoseValue",
			"GlucoseTutorial",
			"GlucoseTutorial1",
			"GlucoseTutorial2",
			"GlucoseTaken",
			"WeightTaken",
			"PulseOxTaken",
			"BloodPressureTaken",
			"TempTaken",

			"PulseOxTutorial",
			"PulseOxTutorial2",
			"BloodPressureTutorial",
			"BloodPressureTutorial1",
			"BloodPressureTutorial2",
			"WeightTutorial",
			"TempTutorial1",
			"TempTutorial2",

			"TempIntro",
			"BloodPressureIntro",
			"WeightIntro",
			"GlucoseIntro",

			"PlayStretchingVideo",
			"stretchingDone",

			//diagrams
			"showBloodPressureDiagram",
			"showHRSummaryDiagram",
			"showOxySummaryDiagram",
			"showTemperatureDiagram",
			"showWeightDiagram",
			"hideHRDiagram",
			"Buttons",						//not sure what this does, in DisplayWeight intent, actually think it shows SummaryDiagram.jsx


			"StartInstructions", 			//instructions for gait scene
			"GaitDone",

			//asthma
			"PulseOxTutorial",
			"showYoga",
			"showAlertTab",
			"playYogaVideo",

			//nutrition
			"showPlatePro",
			"hidePlatePro",
			"showMeals1",
			"hideMeals1",
			"showMeals2",
			"hideMeals2",
			"showList1",
			"showList2",
			"hideList1",
			"hideList2",
			"ShowIdeas",
			"HideIdeas",
			"ShowIdeasButton",
			"HideIdeasButton",
			"showIdeasBtn",
			"hideIdeasBtn",
			"showNextIdeaBtn",
			"hideNextIdeaBtn",
			"HideNextIdeaBtn",
			"ShowNextIdeaButton",
			"showNextIdeasBtn",
			"hideNextIdeasBtn",
			"showListBtn",
			"hideListBtn",
			"showNextBtn",
			"ShowNext",
			"HideNext",
			"NutritionDone",
			"HideReady",
			"ready",
			"ReadyBtn",
			"readybtn",

			"PopulateMedTable",
			"ListDone",
			"hideNextBtn",


			//med heart disease
			"MissedMedList",
			"showReadyAddison",
			"ReadyAddison",
			"SceneReset",
			"GreatJob",
			"ActiveAlarm",
			"DoneVisibility",
			"MedIntro",
			"showStopSmoking1",
			"showStopSmoking2",

			//coping
			"PlayBreathingExercise",
			"sBetter",
			"hBetter",

			"breathingExercise",
			"breathingExerciseDone",
			"showStressed",
			"hideStressed",
			"ShowFeelingBetterOne",
			"HideFeelingBetterOne",
			"ShowFocusBreathingVideo",
			"HideFocusBreathingVideo",
			//"BeginChatbot",

			"AneltoReceived",
			"VitalsTaken",
			"VitalsDone",
			"showFinished",
			"CloseAddisonBtn",
			"btndone",
			"snooze_last",
			"snooze_2ndLast",
			"snooze1",
			"snoozeDone",
			"YesNoGoAway",
			"TabletLook",
			"playHulaHoopVideo",
			"PollenCountReceived",
			"showPollenCount",
			"NextMedList",
			"sLeaf",
			"sLife",
			"hLife",
			"sPhoto",
			"sCook",
			"hCook",
			"hLeaf",
			"hLeaf",
			"hPhoto",
			
			"UIReset",
			"showNutritionFinished",
			"ShowLoadingBackground",
			"HideLoadingBackground",
			"hideSplashScreen",
			"ShowSceneSelectMenu",
			"HideSceneSelectMenu",

			//CFRSI
			"StartInstructions",
			"StopInstructions",
			"TermsShow",
			"talkingTime",
			"AddyOut",
			"Begin",
			"PagesHide",
			"PagesShow",
			"TermsShow",
			"TermsHide",



			//general
			"PlayingNewScene",

		];

		//array not currently used
		this.sceneSwitchEvents = [
			"CS_Scene",
			"RR_Scene" ,
			"Diabetes_Coping",
			"Diabetes_Vitals",
			"Diabetes_Meds",
			"Diabetes_Gait",
			"Diabetes_Nutrition",
			"ActivityMonitoring",

			"playVitalsSceneDiabetes",
			"playVitalsSceneAsthma",
			"playVitalsSceneHeartDisease",
			"playMedSceneDiabetes",
			"playMedSceneAsthma",
			"playMedSceneHeartDisease",
			"playNutritionScene",
			"playActivityMonitoringScene",
			"playHelpScene",
			"playGaitSceneDiabetes",
			"playGaitSceneHeartDisease",
			"playIntroScene",
			"playCopingScene",

		];

		this.onHubCapsule = this.onHubCapsule.bind(this);

		this.getSceneController = this.getSceneController.bind(this);
		this.updateSceneController = this.updateSceneController.bind(this);
		this.putSceneController = this.putSceneController.bind(this);

		this.dispatchPlay = this.dispatchPlay.bind(this);
		this.dispatchLoad = this.dispatchLoad.bind(this);
		this.dispatchPlayCopingScene = this.dispatchPlayCopingScene.bind(this);
		this.dispatchPlayAsthmaVitals = this.dispatchPlayAsthmaVitals.bind(this);
		this.dispatchPlayDiabetesVitals = this.dispatchPlayDiabetesVitals.bind(this);
		this.dispatchPlayHeartDiseaseVitals = this.dispatchPlayHeartDiseaseVitals.bind(this);
		this.dispatchPlayAsthmaMedReminder = this.dispatchPlayAsthmaMedReminder.bind(this);
		this.dispatchPlayDiabetesMedReminder = this.dispatchPlayDiabetesMedReminder.bind(this);
		this.dispatchPlayHeartDiseaseMedReminder = this.dispatchPlayHeartDiseaseMedReminder.bind(this);
		this.dispatchPlayGaitDiabetesScene = this.dispatchPlayGaitDiabetesScene.bind(this);
		this.dispatchPlayGaitHeartDiseaseScene = this.dispatchPlayGaitHeartDiseaseScene.bind(this);

		this.dispatchPlayCFRSI = this.dispatchPlayCFRSI.bind(this);
		this.dispatchPlayCFRSI_Maria = this.dispatchPlayCFRSI_Maria.bind(this);

		this.dispatchPlayIntro = this.dispatchPlayIntro.bind(this);
		this.dispatchPlayHelpScene = this.dispatchPlayHelpScene.bind(this);

		this.emit = this.emit.bind(this);
		this.addListener = this.addListener.bind(this);
		this.initListeners = this.initListeners.bind(this);
		// this.cleanupListeners = this.cleanupListeners.bind(this);
		this.generateCallbacks = this.generateCallbacks.bind(this);
		this.sumerianListenOnHub = this.sumerianListenOnHub.bind(this);
		this.initHubDispatchFromSumerianEmit = this.initHubDispatchFromSumerianEmit.bind(this);

		this.generateCallbacks();
		this.initListeners();
	}

	emit(channel, data) {
		console.log(`in AmplifySceneLoader.emit, emitting ${channel} with payload of ${JSON.stringify(data, null, 2)}`)
		this.sceneController.sumerian.SystemBus.emit(channel, data);
	}

	addListener(channel, callback) {
		console.log(`event ${channel} added as listener to SystemBus with callback of ${JSON.stringify(callback)}...`);
		this.sceneController.sumerian.SystemBus.addListener(channel, callback);
	}

	sumerianListenOnHub(channel, payload) {
		if(!this.sumerianEmits.hasOwnProperty(channel)) {
			this.sumerianEmits.push(channel);

			let data = payload || {};

			this.addListener(channel, (channel) => Hub.dispatch(channel, data));
		}
	}

	generateCallbacks() {
		let generatePlayCallback = (sceneConfig) => {
			return () => sceneConfig.events.play.forEach( (event) => {
				this.sceneController.sumerian.SystemBus.emit(event, sceneConfig);
				Hub.dispatch(event); //also notify components of scene switch
			});
		};

		let generateLoadCallback = (sceneConfig) => {
			return () => sceneConfig.events.load.forEach( (event) => {
				this.sceneController.sumerian.SystemBus.emit(event, sceneConfig)
				Hub.dispatch(event); //also notify components of scene load
			});
		}

		for(var sceneConfig in this.sceneConfigs) {
			let config = this.sceneConfigs[sceneConfig];

			config.playCallback = generatePlayCallback(config);
			config.loadCallback = generateLoadCallback(config);
		}
	}

	initHubDispatchFromSumerianEmit() {
		this.sumerianEmits.forEach( (event) => {
			this.sceneController.sumerian.SystemBus.addListener(event, (data) => {
				console.log(`event ${event} with payload ${JSON.stringify(data, null, 2)} detected from Sumerian, dispatching from Hub now...`);
				Hub.dispatch(event, data);
			});
		});
	}

	initListeners() {
		// this.sumerianEmits.forEach( (event) => {
		// 	Hub.listen(event, this);
		// })

		for(var sceneConfig in sceneConfigs) {
			let config = this.sceneConfigs[sceneConfig];

			Hub.listen(config.events.play, this);
			Hub.listen(config.events.load, this);
		}
	}

	initSceneSwitchCallbacks() {
		this.sceneController.sumerian.SystemBus.addListener("Diabetes_Coping", (data) => {
			console.log("Diabetes_Coping event detected");
			this.dispatchPlayCopingScene();
		});

		this.sceneController.sumerian.SystemBus.addListener("Diabetes_Vitals", (data) => {
			console.log("Diabetes_Vitals event detected");
			this.dispatchPlayDiabetesVitals();
		});

		this.sceneController.sumerian.SystemBus.addListener("Diabetes_Meds", (data) => {
			console.log("Diabetes_Meds event detected");
			this.dispatchPlayDiabetesMedReminder();
		});

		this.sceneController.sumerian.SystemBus.addListener("Diabetes_Gait", (data) => {
			console.log("Diabetes_Gait event detected");
			this.dispatchPlayGaitDiabetesScene();
		});

		this.sceneController.sumerian.SystemBus.addListener("Diabetes_Nutrition", (data) => {
			console.log("Diabetes_Nutrition event detected");
			this.dispatchPlayNutritionScene();
		});

		this.sceneController.sumerian.SystemBus.addListener("CS_Scene", (data) => {
			console.log("CS_Scene event detected; no scene to dispatch yet");
			Hub.dispatch("CS_Scene");
		});

		this.sceneController.sumerian.SystemBus.addListener("ActivityMonitoring", (data) => {
			console.log("ActivityMonitoring event detected");
			this.dispatchPlayActivityMonitoringScene();
		});
	}
/*
	cleanupListeners() {
		for(var sceneConfig in sceneConfigs) {
			// let config = this.sceneConfigs[sceneConfig];

			//TODO: cleanup listeners when Hub has cleanup method
			// Hub.removeListener(config.events.play, this);
			// Hub.removeListener(config.events.load, this);
		}
	}
*/
	async updateSceneController() {
		this.state.sceneController = await XR.getSceneController(this.state.currentScene.name);
	}

	putSceneController(sceneController) {
		this.state.sceneController = sceneController;
		this.sceneController = sceneController;
	}

	getSceneController() {
		return this.sceneController;
	}

	getCurrentScene() {
		return this.state.sceneConfig;
	}

	async dispatchPlay(sceneConfig) {
		Hub.dispatch("UIReset");
		Hub.dispatch("ShowBackgroundImage");
		Hub.dispatch("ShowLoadingBackground");
		Hub.dispatch("PlayingNewScene", sceneConfig);
		console.log("in dispatchPlay, sceneConfig: ", sceneConfig);
		this.state.currentScene = sceneConfig;
		this.currentScene = sceneConfig;
		if(this.currentScene.name === "helpScene") Hub.dispatch("loadHelpScene");
		this.state.sceneController.sumerian.SystemBus.emit(sceneConfig.events.play, sceneConfig);

	}

	async dispatchLoad(sceneConfig) {
		console.log("in dispatchLoad, sceneConfig: ", sceneConfig);

		this.state.sceneController.sumerian.SystemBus.emit(sceneConfig.events.load, sceneConfig);
	}

	dispatchPlayIntro() {
		const config = sceneConfigs.introScene;
		this.dispatchPlay(config);
	}

	dispatchPlayCFRSI() {
		const config = sceneConfigs.gaitSceneCFRSI;
		this.dispatchPlay(config);
	}

	dispatchPlayCFRSI_Maria() {
		const config = sceneConfigs.gaitSceneCFRSI_Maria;
		this.dispatchPlay(config);
	}

	dispatchLoadCopingScene() {
		const config = sceneConfigs.copingScene;
		this.dispatchLoad(config);
	}

	dispatchLoadActivityMonitoringScene() {
		const config = sceneConfigs.activityMonitoringScene;
		this.dispatchLoad(config);
	}

	dispatchLoadNutritionScene() {
		const config = sceneConfigs.nutritionScene;
		this.dispatchLoad(config);
	}


	dispatchPlayCopingScene() {
		const config = sceneConfigs.copingScene;
		this.dispatchPlay(config);
	}

	dispatchPlayActivityMonitoringScene() {
		const config = sceneConfigs.activityMonitoringScene;
		this.dispatchPlay(config);
	}


	dispatchPlayNutritionScene() {
		const config = sceneConfigs.nutritionScene;
		this.dispatchPlay(config);
	}

	dispatchPlayGaitDiabetesScene() {
		const config = sceneConfigs.gaitSceneDiabetes;
		this.dispatchPlay(config);
		Hub.dispatch("playGaitSceneDiabetes");
	}

	dispatchPlayGaitHeartDiseaseScene() {
		const config = sceneConfigs.gaitSceneHeartDisease;
		this.dispatchPlay(config);
		Hub.dispatch("playGaitSceneHeartDisease");
	}
	dispatchPlayAsthmaVitals() {
		const config = sceneConfigs.vitalsSceneAsthma;
		this.dispatchPlay(config);
	}

	dispatchPlayDiabetesVitals() {
		const config = sceneConfigs.vitalsSceneDiabetes;
		this.dispatchPlay(config);
	}

	dispatchPlayHeartDiseaseVitals() {
		const config = sceneConfigs.vitalsSceneHeartDisease;
		this.dispatchPlay(config);
	}

	dispatchPlayAsthmaMedReminder() {
		const config = sceneConfigs.medSceneAsthma;
		this.dispatchPlay(config);
	}

	dispatchPlayDiabetesMedReminder() {
		const config = sceneConfigs.medSceneDiabetes;
		this.dispatchPlay(config);
	}

	dispatchPlayHeartDiseaseMedReminder() {
		const config = sceneConfigs.medSceneHeartDisease;
		this.dispatchPlay(config);
	}

	dispatchPlayHelpScene() {
		const config = sceneConfigs.helpScene;
		this.dispatchPlay(config);
	}

	async loadScene(sceneConfig) {
		await XR.loadScene(sceneConfig.name, sceneConfig.domId);
	}

	async playScene(sceneName) {
		await XR.start(sceneName);
	}

	async onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

		// if( !XR.isSceneLoaded(this.sceneConfigs.introScene.name) ) return; //don't dispatch any events until the initial scene is loaded

		console.log(`in AmplifySceneLoader.onHubCapsule, channel: ${channel}, payload: ${JSON.stringify(payload, null, 2)}`);

		for(var sceneConfig in this.state.sceneConfigs) {
			let config = this.state.sceneConfigs[sceneConfig];

			if(channel === config.events.load) {
				this.dispatchLoad(config);
			}

			if(channel === config.events.play) {
				this.dispatchPlay(config);
			}
		}
	}

	getConfig(sceneKey) {
		return this.sceneConfigs[sceneKey];
	}

	sceneConfigs() {
		return this.sceneConfigs;
	}
} //end AmplifySceneLoader



export { sceneConfigs };

// window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
