import React from 'react';
import { Hub } from 'aws-amplify'; // eslint-disable-line no-unused-vars

export default class ActivityMonitoringSceneUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        // this.state.on = this.props.on;
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    toggle() {
        this.setState({on: !this.state.on});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars


    }

	render() {
      return (
		<div className={this.state.on ? "" : "hidden"}>

		</div>
	  );
	}
}