import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class NextIdeaButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on;

        Hub.listen("ShowNextIdea", this);
        Hub.listen("ShowNextIdeaButton", this);
        Hub.listen("ShowNextIdeaBtn", this);
        Hub.listen("showNextIdeaBtn", this);
        Hub.listen("showNextIdeasBtn", this);
        Hub.listen("HideNextIdea", this);
        Hub.listen("HideNextIdeaButton", this);
        Hub.listen("hideNextBtn", this);
        Hub.listen("toggleNextIdea", this);
        Hub.listen("UIReset", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        this.handleClick = this.handleClick.bind(this);
        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      window.amplifySceneLoader.emit("post_to_lex", "next idea");
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
           this.reset();
        }

        else if(channel === "ShowNextIdea" || channel === "ShowNextIdeaButton" || channel === "showNextIdeaButton" || channel === "showNextIdeasBtn") {
        	this.setState({on: true});
        }

        else if(channel === "HideNextIdea" || channel === "HideNextIdeaButton" || channel === "hideNextIdeaButton" || channel === "hideNextBtn") {
        	this.setState({on: false});
        }

        else if(channel === "toggleNextIdea") {
        	this.setState({on: !(this.state.on)});
        }
    }

    render() {
        return (
        	<button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnNextIdea">Next</button>
        );
    }
}
