import React, { Component } from 'react';
import { XR, Hub } from 'aws-amplify';

var timer;

export default class GaitAppUITerms extends Component {
    getInitialState() {
        return {
            on: false
        }
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen( "UIReset", this );
        Hub.listen( "ToggleThisComponent", this );
        Hub.listen( "ShowThisComponent", this );
        Hub.listen( "HideThisComponent", this );

        this.show = this.show.bind( this );
        this.hide = this.hide.bind( this );
        this.reset = this.reset.bind( this );
        this.getInitialState = this.getInitialState.bind( this );
        this.onHubCapsule = this.onHubCapsule.bind( this );
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now   
    componentDidUpdate() {

    }

    reset() {
        this.setState( this.getInitialState() );
    }

    show() {
        this.setState( { on: true } );
    }


    hide() {
        this.setState( { on: false } );
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars


    }


    scrollUp() {
        timer = setInterval( function () {
            document.getElementById( 'Terms' ).scrollBy( 0, 10 );
        }, 10 );
    }

    scrollDown() {
        timer = setInterval( function () {
            document.getElementById( 'Terms' ).scrollBy( 0, -10 );
        }, 10 );
    }

    render() {
        if ( !( this.props.on ) ) return ( null );

        return (


        <div className='agreementDiv' id="Terms">
            <div id="divimg">
                <div>
                    <h2>Electronic Caregiver Fall Risk Assessment Laboratory Agreement</h2>
                    <br />
                RELEASE OF INFORMATION AND WAIVER OF LIABILITY 
                <br/><br/>
                 
                IN CONSIDERATION of being permitted to participating in any way in the Fall Risk Assessment Laboratory Event(s) or being permitted to enter for any purpose any RESTRICTED AREA (defined as any area where Electronic Caregiver Fall Risk Assessment Laboratory equipment or personnel is present, including computer devices), EACH OF THE UNDERSIGNED, for himself or herself, his or her personal representatives, heirs, and next of kin:
                <br/><br/>

                1. Acknowledges, agrees, and represents that he has or will immediately upon entering any of such RESTRICTED AREAS, and will continuously thereafter, inspect the RESTRICTED AREAS which he/she enters, and he/she further agrees and warrants that, if at any time, he is in or about RESTRICTED AREAS and he feels anything to be unsafe, he will immediately advise the Lab Technicians of such and if necessary will leave the RESTRICTED AREAS and/or refuse to participate further in the Electronic Caregiver Fall Risk Assessment Laboratory Event(s).
                <br/><br/>

                2. HEREBY RELEASES, WAIVES, DISCHARGES AND COVENANTS NOT TO SUE Electronic Caregiver, its officers, directors or its employees, any persons in any RESTRICTED AREA, owners and lessees of premises used to conduct the Electronic Caregiver Fall Risk Assessment Laboratory Event(s), all for the purpose herein referred to as Releasees, FROM ALL LIABILITY TO THE UNDERSIGNED, his personal representatives, assigns, heirs, and next of kin FOR ANY AND ALL LOSS OR DAMAGE, AND ANY CLAIM OR DEMANDS THEREFOR ON ACCOUNT OF INJURY TO THE PERSON OR PROPERTY OR RESULTING IN DEATH OF THE UNDERSIGNED ARISING OUT OF OR RELATED TO THE Electronic Caregiver Fall Risk Assessment Laboratory event(s), WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE.
                <br/><br/>

                3. HEREBY AGREES TO INDEMNIFY AND SAVE AND HOLD HARMLESS the Releasees and each of them FROM ANY LOSS, LIABILITY, DAMAGE, OR COST they may incur arising out of or related to the Electronic Caregiver Fall Risk Assessment Laboratory Event(s) WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE.
                <br/><br/>

                4. HEREBY ASSUMES FULL RESPONSIBILITY FOR ANY RISK OF BODILY INJURY, DEATH OR PROPERTY DAMAGE arising out of or related to the Electronic Caregiver Fall Risk Assessment Laboratory Event(s) whether caused by the NEGLIGENCE OF RELEASEES or otherwise.
                <br/><br/>

                5. HEREBY acknowledges that THE ACTIVITIES OF THE Electronic Caregiver Fall Risk Assessment Laboratory Event(s) may involve the risk of serious injury and/or death and/or property damage.
                <br/><br/>

                6. HEREBY release any information provided within RESTRICTED AREA for use by Electronic Caregiver. This information may be used for follow-up sales regarding appropriate equipment and for research purposes or commercial utilization by research institutions or development companies.
                <br/><br/>
                 
                7. HEREBY agree to release all rights to any images with my likeness taken in a RESTRICTED AREA or taken by me in a RESTRICTED AREA.
                <br/><br/>

                8. HEREBY agrees that this Release and Waiver of Liability and Information, Assumption of Risk and Indemnity Agreement extends to all acts of negligence by the Releasees, and is intended to be as broad and inclusive as is permitted by the laws of the Province or State in which the Electronic Caregiver Fall Risk Assessment Laboratory Event(s) is/are conducted and that if any portion thereof is held invalid, it is agreed that the balance shall, notwithstanding, continue in full legal force and effect.
                <br/><br/>

                9. In valuable and good consideration of my engagement as a model (Participant), upon the
                terms herewith stated, I hereby give Electronic Caregiver, legal representatives and assigns, those for whom The Photographer is acting, and those acting with their authority and permission: 
                <br/><br/>

                a) The unrestricted right and permission forever to copyright and use, re-use, publish, and

                republish photographic portraits or pictures of me or in which I may be included intact or

                in part, composite or distorted in character or form, without restriction as to changes or

                transformations in conjunction with my own or a fictitious name, or reproduction hereof

                in color or otherwise, made through any and all media now or hereafter known for

                illustration, art, promotion, advertising, trade, or any other purpose whatsoever. 
                <br/><br/>

                b) I also permit the use of any text material in connection therewith. 
                <br/><br/>

                c) I hereby relinquish any right that I may have to examine or approve the completed

                product or products or the advertising copy or printed matter that may be used in

                conjunction therewith or the use to which it may be applied. 
                <br/><br/>

                d) I hereby release, discharge and agree to save harmless the Photographer, his/her heirs,

                legal representatives or assigns, and all persons functioning under his/her permission or

                authority, or those for whom he/she is functioning, from any liability by virtue of any

                blurring, distortion, alteration, optical illusion, or use in composite form whether

                intentional or otherwise, that may occur or be produced in the taking of said picture or in

                any subsequent processing thereof, as well as any publication thereof, including without

                limitation any claims for libel or invasion of privacy.
                <br/><br/>

                e) I hereby affirm that I am over the age of majority and have the right to contract in my

                own name. I have read the above authorization, release and agreement, prior to its

                execution; I fully understand the contents thereof. This agreement shall be binding upon

                me and my heirs, legal representatives and assigns. 

                <br/><br/>
                I HAVE READ THIS RELEASE AND WAIVER OF LIABILITY AND RELEASE OF INFORMATION, ASSUMPTION OF RISK AND INDEMNITY AGREEMENT, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT, AND HAVE SIGNED IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT, ASSURANCE OR GUARANTEE BEING MADE TO ME AND INTEND MY SIGNATURE TO BE A COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY TO THE GREATEST EXTENT ALLOWED BY LAW.
                    </div>
        
            </div> 
            <div id = "divbuttons"
                style = { { zIndex: "20000" } } >
                <button type="button" id="agreeButton" ref="agreeButton" className="spin-button" onClick={this.handleAgree}>I agree</button> 
                <button className = 'termsbtn' id = "scrollup" > Down </button> 
                <button className = 'termsbtn' id = "scrolldown" > Up </button> 

            </div>
        </div>
        );
    }
}