import React from 'react';
import { XR } from 'aws-amplify';
import * as AWS from 'aws-sdk';

new AWS.Iot();
new AWS.IotData({endpoint: 'my.host.tld'});
new AWS.Polly();
new AWS.LexRuntime();

export default class AppSceneInterface extends React.Component {
  getInitialState() {
    return {
      loaded: false,
      load: false,
      play: false
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onHubCapsule = this.onHubCapsule.bind(this);

        
    new AWS.Iot();
    new AWS.IotData({endpoint: 'my.host.tld'});
    new AWS.Polly();
    new AWS.LexRuntime();
  } //end ctor  


  componentDidMount() {

  }

  componentDidUpdate() {
    if(this.state.loaded && XR.isSceneLoaded(this.state.sceneName)) {  
      this.setState({loaded: true})
      // Hub.dispatch("sceneLoaded", {}, "SceneLoaded");
      if (XR.isMuted(this.state.sceneName)) {      // The scene is muted
          XR.setMuted(this.state.sceneName, false) // Unmute
      }
    }
  }

  toggle() {
    this.setState({play: !this.state.play }); //toggle 
  }

  hide() {
    this.setState({play: false});
  }

  show() {
    this.setState({play: true});
  }

  async loadScene() {
    await XR.loadScene(this.state.sceneName, this.state.domId);
  }

  async playScene() {
    if(! (XR.isSceneLoaded(this.state.sceneName)) ) {
      await this.loadScene();
    }

    await XR.start(this.state.sceneName);
  }
}; //end AppSceneMedInterface