import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class BloodPressureTutorialImageThree extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("BloodPressureTutorial3", this);
        Hub.listen("BloodPressureTaken", this);
        Hub.listen("BloodPressureTutorial1", this);
        Hub.listen("BloodPressureIntro", this);
        Hub.listen("UIReset", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
            return;
        }

        if(channel === "BloodPressureTutorial3" || channel === "BloodPressureIntro") {
            this.setState({on: true});
        }

        else if(channel === "BloodPressureTaken") {
            this.setState({on: false})
        }
    }

    render() {
            return (
                    <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} alt="Blood Pressure Tutorial 3" src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/BloodPressure+3.jpg"/>
		);
	}
}
