import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
// import Background from './svg/transitionBackground.svg';

export default class LoadingBackground extends Component {
		getInitialState() {
		return {
			on: false,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

		this.sceneSwitchEvents = [
			"CS_Scene",
			"RR_Scene" ,
			"Diabetes_Coping",
			"Diabetes_Vitals",
			"Diabetes_Meds",
			"Diabetes_Gait",
			"Diabetes_Nutrition",
			"ActivityMonitoring",

			"playVitalsSceneDiabetes",
			"playVitalsSceneAsthma",
			"playVitalsSceneHeartDisease",
			"playMedSceneDiabetes",
			"playMedSceneAsthma",
			"playMedSceneHeartDisease",
			"playNutritionScene",
			"playActivityMonitoringScene",
			"playHelpScene",
			"playGaitSceneDiabetes",
			"playGaitSceneHeartDisease",
			"playIntroScene",
			"playCopingScene",
            "playGaitSceneCFRSI",
		];

        Hub.listen("ShowLoadingBackground", this, "LoadingBackground");
        Hub.listen("HideLoadingBackground", this, "LoadingBackground");
        Hub.listen("UIReset", this); //don't remove this line
        Hub.listen("ShowComponentName", this);  //TODO: add your show listeners
        Hub.listen("HideComponentName", this);  //TODO: add your hide listeners
        //if this component reacts to an event that toggles `this.state.on`, you'll probably need a toggle listener and `else if` too.

        this.sceneSwitchEvents.forEach( (channel) => {
        	Hub.listen(channel, this, "LoadingBackground");
        });

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        // uncomment this line only if this is a button that needs to handle a click
        // this.handleClick = this.handleClick.bind(this);
    }

    //This method is called when this component gets rendered into the virtual DOM, which means it's sub-components can be rendered.
    //You probably own't need this.
    componentDidMount() { }

    reset() { this.setState(this.getInitialState()); }

    show() { this.setState({on: true}); }

    hide() { this.setState({on: false}); } 

    /*** uncomment these lines if this is a button that needs to handle a click ***/
    // handleClick(event) {
    //  TODO: post_to_lex an utterance, window.amplifySceneLoader.emit if necessary, hide or show this component, Hub.dispatch to other components, */
    // }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "ShowLoadingBackground") { 
            this.show()
        } else if (channel === "HideLoadingBackground") {
            this.hide();
        }
    } //end onHubCapsule


    //any HMTL, or sub-components containing HTML, go here:
    render() {
        return this.state.on ? ( 
        	<img src={"https://s3.amazonaws.com/ecg-sumerian-assets/transitionBackground.jpg"} className="App" style={{zIndex: "50000"}}  alt=""/>
            //TODO: your HTML here

        ) : (null);
    } //end render
}
