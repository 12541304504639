//AppHeader.js
//
import React, {Component} from 'react';
import logoECG from '../assets/addisoncare.jpg';
import config from '../spaConfig.js';

export default class AppHeader extends Component {
  getInitialState() {
    return {
      headerTimeout: config.headerTimeout, //1 seconds
      on: true
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    console.log("in App constructor, this.state:", this.state);

    // this.toggleHeader = this.toggleHeader.bind(this);
    // this.beginTimeout = this.beginTimeout.bind(this);
  } //end ctor


  toggleHeader() {
    this.setState({on: !this.state.on }); //toggle 
  }


  beginTimeout() {
    setTimeout( () => this.toggleHeader(), this.state.headerTimeout)
  }

  render() {
    return (
        <header className={this.state.on ? "App-header animated slideOutUp delay-4s forwards" : "App-header-hidden"} id="header" onLoad={this.beginTimeout.bind(this)}>
          <img src={logoECG} className="App-logo" alt="logo"/>
          <h1 className="App-title">Addison Care - CES Demo</h1>
        </header>      
    );
  }
}; //end AppHeader
