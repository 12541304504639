import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class MindfulnessCookingTab extends Component {
  getInitialState() {
    return {
      on: false
    }
  }

  constructor( props ) {
    super( props );
    this.state = this.getInitialState();
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.reset = this.reset.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);

    Hub.listen("UIReset", this);
    Hub.listen("sCook", this);
    Hub.listen("hCook", this);

  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  show() {
    this.setState({on: true});
  }
  hide() {
    this.setState({on: false});
  }
  reset() {
      this.setState(this.getInitialState());
  }

  onHubCapsule( capsule ) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    if(channel === "UIReset") {
      this.reset();
    }
    else if(channel === "sCook") {
      this.show();
    }
    else if(channel === "hCook") {
      this.hide();
    }
  }


  render() { 
    return (
      <div className={this.state.on ? "containerA" : "hidden"}>
        <div className="body">
          <img style={{width:"30vw", paddingTop:"4vh"}} src="https://s3.amazonaws.com/ecg-sumerian-assets/cook1.jpg" alt=""/>
        </div>
      </div>
    );
  }
}
