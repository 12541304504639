import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AWS from 'aws-sdk';

function getCookieInformation( name ) {
    let cookieItems = decodeURIComponent( document.cookie )
        .split( ';' )
    for ( let i = 0; i < cookieItems.length; i++ ) {
        let index = cookieItems[ i ].search( name + '=' )
        if ( index > -1 )
            return cookieItems[ i ].substr( index + name.length + 1 ) // +1 for the '='
    }
    return null
}
 



export default class GaitAppUIInit extends Component {
	getInitialState() {
		return {
			on: true,
            visited: false,
            counter: 0,
            firstSetup: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("ToggleThisComponent", this);
        Hub.listen("ShowThisComponent", this);
        Hub.listen("HideThisComponent", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getCameras = this.getCameras.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        window.amplifySceneLoader.emit("gotCam");

        // document.getElementById("hideBakground").style.display = "none";
        // var dropdown = document.getElementsByTagName('select')[0];
        let dropdown = this.refs.select;
        document.cookie = "camName=" +dropdown.options[dropdown.selectedIndex].value+ ";";
        console.log(dropdown.options[dropdown.selectedIndex].value);

        this.setState({firstSetup: false});
    }

    //todo: skip to next scene if this scene was previously visited
    componentDidMount() {
        let gCamName = getCookieInformation('camName');

        if(gCamName) {
            this.setState({firstSetup: false});
        }

        this.getCameras();
    }

    getCameras() {
        console.log("Getting Cameras")
        let iot = new AWS.Iot();
        var params = {
            thingTypeName: "Kiosk_Camera_Type"
        }
        let dropdown = this.refs.select;
     
        iot.listThings(params, (err, data) => {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                console.log("listThings")
                console.log(data)
                data.things.forEach((thing) => {

                    console.log(thing.thingName)
                    let option = document.createElement("option");
                    option.text = thing.thingName;
                    dropdown.options.add(option);
                });
            }               // successful response
        });
     
    }


    //ignore this for now
    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
           this.reset();
        }
        
        if(channel === "ShowReady") {
           this.show();
        }

        if(channel === "HideReady") {
          this.hide();
        }

        if(channel === "toggleReady") {
          this.setState({on: !(this.state.on)});
        }
    }



    render() {
        if(!(this.state.firstSetup)) return (null);

        // let camName = getCookieInformation('camName');
        var decodedCookie = decodeURIComponent(document.cookie);
        var CookieItems = decodedCookie.split(';');

        CookieItems.forEach((cookie) => {

        var index = cookie.search('camName=');
        if (index > -1){
            return (null);
        }
        },true);

        const setupStyle = {
            zIndex: "100000",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }
        
        return ( 
        <div style={setupStyle}>
			<h1>This is the first time this Kiosk has been setup.</h1>

			<p>
			 Please choose the camera from the dropdown below for data recording purposes. After selecting confirm camera, this dropdown and message should no longer appear.
			 <br /> If it does, please Contact the Electronic Caregiver at 1-800-252-7655 and inform them of the issues. Thank you.
			</p>

			<select ref="select">
				<option>Select Camera</option>
			</select>
			  
			  
			  <br />
			  
			  <div className="confirmLocation">
				<button type="button" id="confirmLocationID" className="location" onClick={this.handleClick}>Confirm Camera</button>
			  </div>
	    </div>		
        
        );
    }
}