import { Hub } from 'aws-amplify';
import React from 'react';
import { Client } from 'boardgame.io/react';
import { Game, INVALID_MOVE } from 'boardgame.io/core';
import { AI } from 'boardgame.io/ai';
import _ from 'lodash';
import $ from 'jquery';

// import ReactBoard from 'react-board';
// var draughts = require( 'draughts' ).draughts;
// console.log("draughts: ", draughts);
// var draughts = new draughts();

//https://docs.sumerian.amazonaws.com/tutorials/create/intermediate/amplify-react-appsync/
//
//https://www.npmjs.com/package/draughts

function IsVictory(cells) {
    // return draughts.game_over();
}

const NUM_TILES = 8 * 8; //8 tiles wide, 8 tiles deep

const CheckersGame = Game({
    setup: () => {
        let G = {
            tiles: Array(NUM_TILES).fill(null)
        }

        let whiteStartingPos = []
        let blackStartingPos = []

        //generate initial starting positions
        // G.tiles[]


        return G;
    },

    moves: {
        clickCell(G, ctx, id) {

        },

        movePiece(G, ctx, fromId, toId) {
            // draughts.move( `${fromId}-${toId}` )
        }

    },


    flow: {
        movesPerTurn: 1,



        endGameIf: (G, ctx) => {
            if (IsVictory(G.tiles)) {
                return { winner: ctx.currentPlayer }
            }
        },
    }
});

class CheckersBoard extends React.Component {
    isActive(id) {
        if (!this.props.isActive) return false;
        if (this.props.G.tiles[id] !== 'X') return false;
        return true;
    }

    getInitialState() {
        return {
            on: false
        }
    }

    clickHandler({ col, row, cellName, cellValue }) {
        // ...
    }

    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);

        this.board = [
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
        ];

        this.state = this.getInitialState();

        this.state.whiteDraught = "⛂";
        this.state.whiteKing = "⛁";
        this.state.blackDraught = "⛂";
        this.state.blackKing = "⛃";

        this.blackPositions = _.range(0,64,2);
        this.whitePositions = _.range(1,63,1);

        Hub.dispatch("ToggleSceneSelectMenu");
    }

    componentDidMount() {
    	$('.react-board-cell').each( index => {
    		index in this.whitePositions ? 
    		$(this).css('background-color', 'white') : 
    		$(this).css('background-color', 'brown'); 
    	});
    }

    componentDidMount() {
    	$('.react-board-cell').each( index => {
    		index % 2 === 0 ? 
    		$(this).css('background-color', 'white') : 
    		$(this).css('background-color', 'black'); 
    	});
    }

    render() {
        if (!(this.props.on)) return (null);

        let winner = '';
        if (this.props.ctx.gameover) {
            winner =
                this.props.ctx.gameover.winner !== undefined ? (
                    <div id="winner">BINGO!</div>
                ) : (
                    <div id="winner">Draw!</div>
                );
        }

        const whiteCellStyle = {
            border: '1px solid #555',
            padding: "2.5vh 2vw",
            width: '2vw',
            height: '2.5vh',
            lineHeight: '50px',
            textAlign: 'center',
            backgroundColor: "white"
        };

        const blackCellStyle = {
            border: '1px solid #555',
            padding: "2.5vh 2vw",
            width: '2vw',
            height: '2.5vh',
            lineHeight: '50px',
            textAlign: 'center',
            backgroundColor: 'brown' //I know how awful this code duplication is :D
        };

        let tbody = [];
        let color = false;
        for (let i = 0; i < 8; i++) {
            let cells = [];
            color = !color;
            for (let j = 0; j < 8; j++) {
                const id = 8 * i + j;
                cells.push(
                    <td style={(id % 2 === 0) && color ? (color ? whiteCellStyle : blackCellStyle) : (color ? blackCellStyle : whiteCellStyle)} key={id} className="bingo-board-cell" onClick={() => this.onClick(id)}>
                        {this.props.G.tiles[id]}
                    </td>
                );
            }
            tbody.push(<tr key={i}>{cells}</tr>);
        }

        // $(".bingo-board-cell").each( (index) => {
        //     color ?
        //     $(this).attr('background-color', 'white') :
        //     $(this).attr('background-color', 'black') 

        //     color = !color;
        // })

        return (
            <div className="bingo-boredo" style={{display:"flex", flexDirection:"column",position: "absolute", left:"50vw", top: "17vh", zIndex: "10000"}}>

                <table style={{left:"45vw", top: "20vh"}} id="checkersBoard">
                    <tbody>{tbody}</tbody>
                </table>
                {winner}
            </div>
        );
    }

}


const AppCheckersGame = Client({
    game: CheckersGame,
    board: CheckersBoard,
    debug: false,
    // debug: true,
    numPlayers: 2,

    /*    ai: AI( {
            enumerate: ( G, ctx ) => {
                return draughts.moves();
            },
        } ),*/
});

export default AppCheckersGame;

// export default CheckersBoard;