import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class TemperatureImageOne extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.toggle = this.toggle.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        Hub.listen("TempTutorial", this);
        Hub.listen("TempTutorial1", this);
        Hub.listen("TempTutorial2", this);
        Hub.listen("TempTaken", this);
        Hub.listen("UIReset", this);

    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    toggle() {
        this.setState({on: !this.state.on});
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    reset() {
      this.setState(this.getInitialState()); 
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "TempTutorial" || channel === "TempTutorial1") {
            this.setState({on: true});
        } else if(channel === "UIReset" || channel === "TempTutorial2" || channel === "TempTaken" || channel === "TempIntro") {
            this.setState({on: false});
        }
    }


    render() {

            return (
                <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/Temperature+1.jpg"  alt=""/>
		);
	}
}