import React, { Component } from 'react';
// import { XR, Hub } from 'aws-amplify';

export default class AchievementContainer extends Component {
	getInitialState() {
		return {
			on: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }


    render() {
        return (
        	<div>
			    <div id="achievement-captions">
			        <p id="achievement-header">Days to Token</p>
			        <p id="achievement-score">6/7</p>
			    </div>
			    <div id="summary-container progress" className="summary-header">
			        <div id="summary-header-text">Summary</div>
			        <div id="achievement-container">
			            <div id="bar0" className="achievement-button left-achievement"></div>
			            <div id="bar1" className="achievement-button middle-achievement"></div>
			            <div id="bar2" className="achievement-button middle-achievement"></div>
			            <div id="bar3" className="achievement-button middle-achievement"></div>
			            <div id="bar4" className="achievement-button middle-achievement"></div>
			            <div id="bar5" className="achievement-button middle-achievement"></div>
			            <div id="bar6" className="achievement-button right-achievement"></div>
			        </div>
			    </div> 
		    </div>	
        );
    }
}