//HeartDiseaseVitalsInterface.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import { TemperatureTutorialImageOne, TemperatureTutorialImageTwo, BloodPressureTutorialImageOne, BloodPressureTutorialImageTwo, BloodPressureTutorialImageThree, ReadyAddisonButton } from '../components';


export default class HeartDiseaseVitalsUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        //this.state.on = this.props.on;

        Hub.listen("playVitalsScenHeartDisease", this);
        Hub.listen("toggleHeartDiseaseVitalsUserInterface", this);
        Hub.listen("BloodPressureTutorial", this);
        Hub.listen("BloodPressureTutorial2", this);
        Hub.listen("BloodPressureTutorial3", this);
        Hub.listen("TempTutorial", this);
        Hub.listen("TempTutorial2", this);

    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
        
        if(channel === "BloodPressureTutorial") {
            this.setState({on: true});
        }
        if(channel === "BloodPressureTutorial2") {
            this.setState({on: true});
        }
        if(channel === "BloodPressureTutorial3") {
            this.setState({on: true});
        }
        if(channel === "TempTutorial") {
            this.setState({on: true});
        }
        if(channel === "TempTutorial2") {
            this.setState({on: true});
        }


    }

	render() {
      return (
        <div className={this.state.on ? "" : "hidden"}>
            <BloodPressureTutorialImageThree />
            <BloodPressureTutorialImageTwo />
            <BloodPressureTutorialImageOne />
            <TemperatureTutorialImageTwo />
            <TemperatureTutorialImageOne />
            <ReadyAddisonButton />
		</div>
	  );
	}
}