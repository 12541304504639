import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class VitalsFinishedButton extends Component {
    getInitialState() {
        return {
            on: false
        }
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on;

        Hub.listen("showFinished", this);
        Hub.listen("VitalsDone", this);
        Hub.listen("toggleFinishedBtn", this);
        Hub.listen("SceneReset", this);
        Hub.listen("finished", this);

        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }



    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      window.amplifySceneLoader.emit("VitalsDone");
    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "showFinished") {
            this.setState({on: true});
        }
        
        else if(channel === "VitalsDone" || "SceneReset" || channel === "finished") {
            this.setState({on: false});
        }
        
        else if(channel === "toggleFinishedBtn") {
            this.setState({on: !(this.state.on)});
        }
    }


    render() {
        return (
            <button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnvitalsfinished">Finished</button>
        );
    }
}