import React, {Component} from 'react';
import $ from 'jquery';
import {Hub} from 'aws-amplify';

export default class MedicationChartTab extends Component {
    getInitialState() {
        return {on: false}
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        Hub.listen("PopulateMedTable", this);
        Hub.listen("SceneReset", this);


        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.buildHtmlTable = this.buildHtmlTable.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.addAllColumnHeaders = this.addAllColumnHeaders.bind(this);
    }

    //ignore this for now
    componentDidMount() {}

    //ignore this for now
    componentDidUpdate() {}

    show() {
        this.setState({on: true});
    }

    hide() {
        this.setState({on: false});
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule(capsule) {
        const {channel, payload} = capsule; // eslint-disable-line no-use-before-define

        if (channel === "PopulateMedTable") {
            if(Array.isArray(payload)){
                this.buildHtmlTable('#excelDataTable', payload);
            } else {
                var meds = [];
                for (var key in payload){
                    meds.push(payload[key]);
                }
                this.buildHtmlTable('#excelDataTable', meds);
            }
            this.show();
        }

         else if (channel === "SceneReset") {
            this.hide();
        }
    }

    buildHtmlTable(selector, medSchedule) {
        $('#excelDataTable').html("") // this clears it out incase double input
        var columns = this.addAllColumnHeaders(medSchedule, selector);
        for (var i = 0; i < medSchedule.length; i++) {
            var row$ = $('<tr/>');
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                var cellValue = medSchedule[i][columns[colIndex]];
                if (cellValue === null) {cellValue = "";}
                row$.append($('<td/>').html(cellValue));
            }
            $(selector).append(row$);
        }
    }

    addAllColumnHeaders(medSchedule, selector) {
        var columnSet = [];
        var headerTr$ = $('<tr/>');
        for (var i = 0; i < medSchedule.length; i++) {
            var rowHash = medSchedule[i];
            for (var key in rowHash) {
                if ($.inArray(key, columnSet) === -1) {
                    columnSet.push(key);
                    headerTr$.append($('<th/>').html(key));
                }
            }
        }
        $(selector).append(headerTr$);
        return columnSet;
    }

    render() {
        return (<div id="MedicationChartTab" className={this.state.on ? "MedicationChartTab" : "hidden"}><table className="med-table" id="excelDataTable"></table></div>);
	}
}
