import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
 
import AmplifySceneLoader,{ sceneConfigs } from '../../AmplifySceneLoader';

export default class GotoButton extends Component {
    getInitialState() {
        return {
            on: false,
            sceneConfig: sceneConfigs.introScene
        }
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
        this.toggleSummary = this.toggleSummary.bind(this);

        Hub.listen("IntroSceneLoaded", this);

        //TODO: disable all buttons to start

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "IntroSceneLoaded") {
            this.setState({on: true});
            //TODO: enable buttons
        } 
    }

    dispatchAsthma(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayAsthmaVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsAsthma");

        // Hub.dispatch(sceneConfigs.vitalsSceneAsthma.events.load, {});
        // Hub.dispatch(sceneConfigs.vitalsSceneAsthma.events.play, {});
        // Hub.dispatch(this.state.sceneConfig.events.play, {});
    }

    dispatchNutrition(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayNutritionScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchNutrition");
    }

    dispatchActivityMonitoring(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayActivityMonitoringScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchActivity");
    }

    dispatchGaitDiabetes(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayGaitDiabetesScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGaitDiabetes");
    }

    dispatchGaitHeartDisease(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayGaitHeartDiseaseScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGaitHeartDisease");
    }

    dispatchCoping(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayCopingScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGait");
    }


    dispatchDiabetes(event) {
        event.preventDefault();

        window.amplifySceneLoader.dispatchPlayDiabetesVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsDiabetes");
    }


    dispatchHeartDisease(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayHeartDiseaseVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsHeartDisease");
    }

    dispatchAsthmaMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayAsthmaMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderAsthma");
    }

    dispatchDiabetesMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayDiabetesMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderDiabetes");
    }

    dispatchHeartDiseaseMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayHeartDiseaseMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderHeartDisease");
    }

    dispatchGaitScene(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchGaitScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGait");
    }


    toggleSummary(event) {
        event.preventDefault();

        Hub.dispatch("toggleSummary", {}, "SummaryDiagram");
    }

    render() {
        return this.state.on ? ( 
            <div className={this.state.on ? "vertical-container ui-button" : "hidden"}>
                {/*<Link to="/vitals-asthma">*/}
                    <button onClick={this.dispatchAsthma} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} id="goto-vitals-asthma">
                        Asthma Vitals
                    </button>
                    <button onClick={this.dispatchDiabetes} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} id="goto-vitals-diabetes">
                        Diabetes Vitals
                    </button>
                    <button onClick={this.dispatchHeartDisease} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} id="goto-vitals-heart-disease">
                        Heart Vitals
                    </button>
                    <button onClick={this.dispatchHeartDiseaseMedReminder} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Heart Med
                    </button>
                    <button onClick={this.dispatchDiabetesMedReminder} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Diabetes Med
                    </button>
                    <button onClick={this.dispatchAsthmaMedReminder} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Asthma Med
                    </button>
                    <button onClick={this.dispatchGaitDiabetes} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Gait Diabetes
                    </button>
                    <button onClick={this.dispatchGaitHeartDisease} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Gait HD
                    </button>
                    <button onClick={this.dispatchNutrition} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Nutrition
                    </button>
                    <button onClick={this.dispatchCoping} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Coping
                    </button>
                    <button onClick={this.dispatchActivityMonitoring} className={this.state.on ? "vertical-item btn-ecg" : "hidden"} >
                        Activity
                    </button>
            </div>
        ) : (<div>Still Loading Sumerian...</div>);
    }
}