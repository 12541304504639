import React, { Component } from 'react';
// import { XR, Hub } from 'aws-amplify';

export default class HelpButton extends Component {
    // constructor( props ) {
    //     super( props );
    // }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }


    render() {
        return (
		<h1 id="gradient">
			<p id="captions"></p>
		</h1>
        );
    }
}