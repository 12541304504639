import React from 'react';
import { Hub } from 'aws-amplify';

// eslint-disable-next-line 
import { MindfulnessCookingTab, MindfulnessLeavesTab, MindfulnessLifeEasierTab, MindfulnessPhotographyTab, StressedButton, NeedHelpButton, FeelBetterButton } from '../components'; // eslint-disable-line react/jsx-pascal-case

export default class CopingSceneUserInterface extends React.Component {

    getInitialState() {
        return {
            on: true,
        }
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        Hub.listen("playCopingScene", this);
        Hub.listen("playIntroScene", this);
        Hub.listen("ShowFeelingBetterOne", this);
        Hub.listen("HideFeelingBetterOne", this);
        Hub.listen("ShowFocusBreathingVideo", this);
        Hub.listen("HideFocusBreathingVideo", this);
        Hub.listen("sCook", this);
        Hub.listen("hCook", this);
        Hub.listen("sLeaf", this);
        Hub.listen("hLeaf", this);
        Hub.listen("sLife", this);
        Hub.listen("hLife", this);
        Hub.listen("sPhoto", this);
        Hub.listen("hPhoto", this);
        Hub.listen("UIReset", this);
        Hub.listen("SceneReset", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    show() {
      this.setState({on: true});
    }
    hide() {
      this.setState({on: false});
    }
    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        // let listenerNames = Object.keys(this.state.listeners);

       if(channel === "UIReset" || channel === 'SceneRest') {
           this.reset();
        }
         else if(channel === "playCopingScene") {
            this.show()
        }
        else if(channel === "playIntroScene") {
            this.show()
        }
        else if(channel === "ShowFocusBreathingVideo") {
            this.show()
        }
        else if(channel === "HideFocusBreathingVideo") {
            this.hide()
        }
        else if(channel === "ShowFeelingBetterOne") {
            this.show()
        }
        else if(channel === "HideFeelingBetterOne") {
            this.hide()
        }
        else if(channel === "sCook") {
            this.show()
        }
        else if(channel === "sLeaf") {
            this.show()
        }
        else if(channel === "sLife") {
            this.show()
        }
        else if(channel === "sPhoto") {
            this.show()
        }

    }

    render() {
      return (
        <div className={this.state.on ? "" : "hidden"}>
        <MindfulnessCookingTab/>
        <MindfulnessLeavesTab/>
        <MindfulnessLifeEasierTab/>
        <MindfulnessPhotographyTab/>
        <StressedButton/>
        <FeelBetterButton/>
        <NeedHelpButton/>

        </div>
      );
    }
}
