
import React from 'react';
import { Hub } from 'aws-amplify'
import {DiabetesSmokingTab, MedicationChartTab, SnoozeTimer } from '../components';


export default class DiabetesMedReminderUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        // this.state.on = this.props.on;

        Hub.listen("playMedSceneDiabetes", this);
        Hub.listen("SceneReset", this);
        Hub.listen("toggleDiabetesMedSceneUserInterface", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "playMedSceneDiabetes") {
            this.setState({on: true});
        }
    }

	render() {
      return (
        <div className={this.state.on ? "" : "hidden"}>
        <DiabetesSmokingTab />
        <MedicationChartTab />
        <SnoozeTimer />
		</div>
	  );
	}
}
