import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class BloodGlucoseTutorialImageTwo extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("GlucoseTutorial2", this);
        Hub.listen("GlucoseTaken", this);
        Hub.listen("GlucoseTutorial1", this);
        Hub.listen("GlucoseIntro", this);
        Hub.listen("UIReset", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
            return;
        }

        if(channel === "GlucoseTutorial2" || channel === "GlucoseIntro") {
            this.setState({on: true});
        }

        else if(channel === "GlucoseTaken") {
            this.setState({on: false})
        }
    }

    render() {
            return (
                    <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} alt="Blood Glucose Tutorial 2" src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/BloodGlucose+2.jpg"/>
		);
	}
}
