import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class BeginButton extends Component {
	getInitialState() {
		return {
			on: false,
            owner: "CFRSI"
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("ShowBeginButton", this);
        Hub.listen("HideBeginButton", this);
        Hub.listen("ToggleBeginButton", this);

        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);  
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset" || channel === "SceneReset") {
            //TODO
        } 

        else if (channel === "ShowBeginButton") {
            this.setState({on: true});
        } 

        else if (channel === "HideBeginButton") {
            this.setState({on: false});
        }
    }

    handleClick(event) {
        // window.amplifySceneLoader.emit("post_to_lex", "begin");
        // window.amplifySceneLoader.emit("post_to_lex", "Begin");
        window.amplifySceneLoader.emit("Begin");
        Hub.dispatch("HideBeginButton");

        if(this.state.owner === "CFRSI") {
            // Hub.dispatch("")
        }
    }


    render() {
        if(!(this.props.on)) return (null);

        const buttonStyle = {
            left: "50vw",
            top: "50vh"
        };

        return (
			<button type="button" id="beginButton" style={buttonStyle} className="begin-btn" onClick={this.handleClick}>Begin</button>
        );
    } 
}