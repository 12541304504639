import React from 'react';
import { XR, Hub } from 'aws-amplify';
// import { SumerianScene } from 'aws-amplify-react';
import { sceneConfigs } from '../AmplifySceneLoader';
import AppSceneInterface from './AppSceneInterface';

var AWS = require('aws-sdk');

export default class AppScene extends AppSceneInterface {
  getInitialState() {
    return {
      load: true,
      loaded: false,
      play: true,
      sceneController: null,
      currentScene: sceneConfigs.introScene,
      sceneConfig: sceneConfigs.introScene,
      sceneName: sceneConfigs.introScene.name,
      domId: sceneConfigs.introScene.domId 
    };
  }
 
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.loadAndStartScene = this.loadAndStartScene.bind(this);

    new AWS.Iot();
    new AWS.IotData({endpoint: 'my.host.tld'});
    new AWS.Polly();
    new AWS.LexRuntime();

    Hub.listen(this.state.sceneConfig.events.play, this);
    Hub.listen(this.state.sceneConfig.events.load, this);
  } //end ctor  

  async componentDidMount() {
      await this.loadAndStartScene();
  }

  componentDidUpdate() {

  }

  toggle() {
    this.setState({play: !this.state.play }); //toggle 
  }

  hide() { 
    this.setState({play: false});
  }

  show() {
     this.setState({play: true});
  }

  async onHubCapsule(capsule) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    // if(channel == this.state.sceneConfig.events.load) {
    //   XR.loadScene(this.state.sceneName, this.state.domId);
    //   this.setState({load: true});
    // }

    // if(channel == this.state.sceneConfig.events.play) {
    //   if( !XR.isSceneLoaded(this.state.sceneName)) {        
    //     await XR.loadScene(this.state.sceneName, this.state.domId);
    //   }

    //   XR.start(this.state.sceneName);
    //   this.setState({play: true});
    // } 
  }    

   async loadAndStartScene() {
      console.log("in loadAndStartScene, this.state.sceneConfig: ", this.state.sceneConfig);

      await XR.loadScene(this.state.sceneConfig.name, this.state.sceneConfig.domId);
      this.setState({loaded: true});
      const controller = XR.getSceneController(this.state.sceneConfig.name);
      this.props.onLoaded(controller);
      XR.start(this.state.sceneConfig.name);
    }

  render() {  
    return (
          <div className={this.state.play ? "App-scene" : "hidden"}>
            <div id={this.state.domId} className={this.state.play ? "App-scene" : "hidden"}></div>
          </div>          
      );
  } 
}; //end AppScene
