// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    account
    readings {
      items {
        id
        timestamp
        reading
      }
      nextToken
    }
    events {
      items {
        id
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        Longitude
        Latitude
      }
      nextToken
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    account
    readings {
      items {
        id
        timestamp
        reading
      }
      nextToken
    }
    events {
      items {
        id
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        Longitude
        Latitude
      }
      nextToken
    }
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    id
    account
    readings {
      items {
        id
        timestamp
        reading
      }
      nextToken
    }
    events {
      items {
        id
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        Longitude
        Latitude
      }
      nextToken
    }
  }
}
`;
export const onCreateReading = `subscription OnCreateReading {
  onCreateReading {
    id
    timestamp
    reading
  }
}
`;
export const onUpdateReading = `subscription OnUpdateReading {
  onUpdateReading {
    id
    timestamp
    reading
  }
}
`;
export const onDeleteReading = `subscription OnDeleteReading {
  onDeleteReading {
    id
    timestamp
    reading
  }
}
`;
export const onCreateEventManagement = `subscription OnCreateEventManagement {
  onCreateEventManagement {
    id
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    Longitude
    Latitude
  }
}
`;
export const onUpdateEventManagement = `subscription OnUpdateEventManagement {
  onUpdateEventManagement {
    id
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    Longitude
    Latitude
  }
}
`;
export const onDeleteEventManagement = `subscription OnDeleteEventManagement {
  onDeleteEventManagement {
    id
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    Longitude
    Latitude
  }
}
`;
export const onCreateSceneEvent = `subscription OnCreateSceneEvent {
  onCreateSceneEvent {
    id
    name
    sceneToLoad
    sceneConfig
  }
}
`;
export const onUpdateSceneEvent = `subscription OnUpdateSceneEvent {
  onUpdateSceneEvent {
    id
    name
    sceneToLoad
    sceneConfig
  }
}
`;
export const onDeleteSceneEvent = `subscription OnDeleteSceneEvent {
  onDeleteSceneEvent {
    id
    name
    sceneToLoad
    sceneConfig
  }
}
`;
export const onCreateBingoTile = `subscription OnCreateBingoTile {
  onCreateBingoTile {
    player {
      playerID
      tiles {
        nextToken
      }
      game {
        id
      }
    }
    letter
    number
  }
}
`;
export const onUpdateBingoTile = `subscription OnUpdateBingoTile {
  onUpdateBingoTile {
    player {
      playerID
      tiles {
        nextToken
      }
      game {
        id
      }
    }
    letter
    number
  }
}
`;
export const onDeleteBingoTile = `subscription OnDeleteBingoTile {
  onDeleteBingoTile {
    player {
      playerID
      tiles {
        nextToken
      }
      game {
        id
      }
    }
    letter
    number
  }
}
`;
export const onCreateBingoPlayer = `subscription OnCreateBingoPlayer {
  onCreateBingoPlayer {
    playerID
    tiles {
      items {
        letter
        number
      }
      nextToken
    }
    game {
      id
      players {
        nextToken
      }
    }
  }
}
`;
export const onUpdateBingoPlayer = `subscription OnUpdateBingoPlayer {
  onUpdateBingoPlayer {
    playerID
    tiles {
      items {
        letter
        number
      }
      nextToken
    }
    game {
      id
      players {
        nextToken
      }
    }
  }
}
`;
export const onDeleteBingoPlayer = `subscription OnDeleteBingoPlayer {
  onDeleteBingoPlayer {
    playerID
    tiles {
      items {
        letter
        number
      }
      nextToken
    }
    game {
      id
      players {
        nextToken
      }
    }
  }
}
`;
export const onCreateBingoGame = `subscription OnCreateBingoGame {
  onCreateBingoGame {
    id
    players {
      items {
        playerID
      }
      nextToken
    }
  }
}
`;
export const onUpdateBingoGame = `subscription OnUpdateBingoGame {
  onUpdateBingoGame {
    id
    players {
      items {
        playerID
      }
      nextToken
    }
  }
}
`;
export const onDeleteBingoGame = `subscription OnDeleteBingoGame {
  onDeleteBingoGame {
    id
    players {
      items {
        playerID
      }
      nextToken
    }
  }
}
`;
