import React from 'react';
import { Hub } from 'aws-amplify';
import { Carousel, Row, Input, Pagination } from 'react-materialize';
import Slider from "react-slick";
import { SaveAllButton, FinishedButton } from '../components';
import $ from 'jquery';


export default class CFRSIPaginatedForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currPage: 0,
        };

      this.onHubCapsule = this.onHubCapsule.bind(this);
      this.handleAgree = this.handleAgree.bind(this);
    }

    onSelect(id) {

    }


    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars


    }

    componentDidMount() {}

   handleAgree(data) {
      window.amplifySceneLoader.emit("AgreementDone", data); //trigger the 
   }


    render() {
        // if(!(this.props.on)) return (null);

        const options = {
            zIndex: 100000
        }

        return (

         <div className={this.props.on ? "container" : "hidden"} id ="mainContainer">
            <section id='User'>
               <div className="article-loop"> 
                  <h1 htmlFor='participantFirstName'>First Name</h1>
                  <input className="userInput" type='text'  id='participantFirstName' name='first_name' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantMiddleName'>Middle Name</h1>
                  <input className="userInput" type='text'  id='participantMiddleName' name='middle_name' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantLastName'>Last Name</h1>
                  <input className="userInput" type='text'  id='participantLastName' name='last_name' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantDob'>Date of Birth</h1>
                  <input className="userInput" type='text'  id='participantDob' name='date_of_birth' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantGender'>Sex</h1>
                  <input className="userInput" type='text'  id='participantGender' name='sex' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='userAddress'>Address</h1>
                  <input className="userInput" type='text'  id='userAddress' name='address' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantCity'>City</h1>
                  <input className="userInput" type='text'  id='participantCity' name='' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='participantZip'>ZIP</h1>
                  <input className="userInput" type='text'  id='participantZip' name='' defaultValue='' />
               </div>

            </section>

            <section id='FallHistory'>
               <div className="article-loop">
                  <h1 htmlFor='fallenThreeYears'>Have you fallen in the past three years?</h1>
                  <input className="userInput" type='text'  id='fallenThreeYears' name='fallenThreeYears' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='fallenOneYear'>Have you fallen in the past year?</h1>
                  <input className="userInput" type='text'  id='fallenOneYear' name='fallenOneYear' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='walkingAid'>Do you use any walking aids?</h1>
                  <input className="userInput" type='text'  id='walkingAid' name='walkingAid' defaultValue='' />
               </div>
               <div className="article-loop">
                  <h1 htmlFor='hasArt'>Do you have Arthritis?</h1>
                  <input className="userInput" type='text'  id='hasArt' name='hasArt' defaultValue='' />
               </div>

            </section>

            <section id="Gait_Result">

               <div className="article-loop" style={{height: "40vh"}}>
                  <canvas id="speed_chart"></canvas>
               </div>

               <div className="article-loop">
                  <h1>Gait Velocity </h1>
                  <input className="YN" type='text'  name='Speed' id='Speed' defaultValue='' />
                  <label htmlFor='Speed'>92.8cm is the velocity at which a person moves forward during walking. Every 10 cm/sec slower an individual walks, their risk save allof falls may increase 6.9%.</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>

               <div className="article-loop" style={{height: "40vh"}}>
                  <canvas id="cadence_chart"></canvas>
               </div>

               <div className="article-loop">
                  <h1>Cadence </h1>
                  <input className="YN"  type='text' name='Cadence' id='Cadence' defaultValue='' />
                  <label htmlFor='Cadence'>101.8 is the number of steps a person takes per minute. For every 10 steps/min slower an individual walks, risk of falls may increase 8.5%.</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>

               <div className="article-loop" style={{height: "40vh"}}>
                  <canvas id="stride_chart"></canvas>
               </div>

               <div className="article-loop">
                  <h1>Stride Length </h1>
                  <input className="YN"  type='text' name='Stride' id='Stride' defaultValue='' />
                  <label htmlFor='Stride'>112.5cm is the norm distance between heel contact points for the same leg. For every 10 cm decreased, risk of falls may increase 9.5%.</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>

               <div className="article-loop" style={{height: "40vh"}}>
                  <canvas id="swing_chart"></canvas>
               </div>

               <div className="article-loop">
                  <h1>Swing Time </h1>
                  <input className="YN"  type='text' name='Swing_Time' id='Swing_Time' defaultValue='' />
                  <label htmlFor='Swing_Time'>36.6% is the percentage of time a person spends with only one leg contacting the ground. Every 10% less time spent in swing time, risk of falls may increase 50.3%.</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>
               <div className="article-loop invisible-article">
                  <label>This is a blank label that will be intentionally skipped</label>
               </div>

               

            </section>


              <div id="inputFields" style={{position: "absolute", display: "flex", width: "70%", height: "30%"}}>
                <div className="YN-buttons">
                  <button type="button" id="yes-button" className="spin-button">Yes</button>
                  <button type="button" id="no-button" className="spin-button">No</button>
                </div>

                <div id="userField" style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                  <button type='button' id='saveBtn' className='submit-button'>Save All</button>
                </div>
                <div style={{marginTop: "-19em", width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                  <button type='button' id='readyBtn' className='submit-button'>Ready</button>
                </div>
              </div>

         </div>
        );
    }

}

// const asRow = (id, comps) => {
//    return (
//       <Row>
//          {...comps}
//       </Row>
//    );
// }