import React, {Component} from 'react';
// import { XR, Hub } from 'aws-amplify';

export default class DiabetesTab extends Component {
	getInitialState() {
		return {
		  on: false
		};
	}

	constructor(props) {
		super(props); 
    	this.state = this.getInitialState();

        this.state.on = this.props.on;
	}	

	componentDidMount() {

	}

	componentDidUpdate() {

	}

	onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
	}


	render() {
		return ( 
			<div className={this.state.on ? "addisonTab" : "hidden"} id="addisonTab">
			  <div id="addisonBanner"> Diabetes Plan of Care
			  <button className="btncloseaddison" id="btncloseaddison">&nbsp;Close&nbsp;</button>
			   </div> 
			  
			    <div id="addisonBtnContainer">
			    <button className="btnTall" id="btnMeals">&nbsp;Nutrition&nbsp;</button>
			    <button className="btnTall" id="btnMeds">&nbsp;Medication&nbsp;</button>
			    <button className="btnTall" id="btnGait">&nbsp;Gait&nbsp;</button>
			      
			    <button className="btnTall" id="btnCoping">&nbsp;I'm Stressed&nbsp;</button>
			    <button className="btnTall" id="btnMeds">&nbsp;Vitals&nbsp;</button>
			      <div id="linebreak"></div>
			    <button className="btnMedium" id="btnTakeMyMeds">&nbsp;Did I take my Meds?&nbsp;</button>  
			    <button className="btnMedium" id="btnNextMed">&nbsp;What is my next medication?&nbsp;</button> 
			    </div>
			</div>

		);
	}
}

