import AWS from 'aws-sdk';
import Amplify, { Auth, XR } from 'aws-amplify';
//import awsconfig from './aws-exports.js';


// import { sceneConfigs } from './AmplifySceneLoader';

var iot = new AWS.Iot();

const introConfig = require('./config/CES_IntroScene.json'); // This file will be generated by the Sumerian AWS Console

// const medReminderAsthmaConfig = require('./config/MedRemind_Asthma_v01_03.json');
// const medReminderDiabetesConfig = require('./config/MedRemind_Diabetes_v01_03.json');
// const medReminderHeartDiseaseConfig = require('./config/MedRemind_HeartDisease_v01_03.json');
// const vitalsAsthmaConfig = require('./config/Vitals_Asthma_v01_02.json');
// const vitalsDiabetesConfig = require('./config/Vitals_Diabetes_v01_02.json');
// const vitalsHeartDiseaseConfig = require('./config/Vitals_HeartDisease_v01_02.json');

// const gaitSceneConfig = require('./config/GaitScene_v01.json'); //gaitSceneConfig sent by Sierra on 12-21-18
// const nutritionSceneConfig = require('./config/NutritionScene_v01.json');
// const activityMonitoringSceneConfig = require('./config/ActivityMonitoringScene_v01.json');
// const copingSceneConfig = require('./config/CopingScene_v01.json');

const sceneConfigs = {
  helpScene: {
    name: "helpScene",
    events: {
      load: ['loadHelpScene'],
      play: ['playHelpScene', 'RR_Scene']
    }
  },
  copingScene: {
    name: "copingScene",
    events: {
      load: ["loadCopingScene"],
      play: ["playCopingScene"],
    },
  },
  activityMonitoringScene: {
    name: "activityMonitoringScene",
    events: {
      load: ["loadActivityMonitoringScene"],
      play: ["playActivityMonitoringScene"],
    },
  },
  nutritionScene: {
    name: "nutritionScene",
    events: {
      load: ["loadNutritionScene"],
      play: ["playNutritionScene"],
    },
  },
  gaitSceneHeartDisease: {
    name: "gaitSceneHeartDisease",
    events: {
      load: ["loadGaitSceneHeartDisease"],
      play: ["playGaitSceneHeartDisease"],
    },
  },
  gaitSceneDiabetes: {
    name: "gaitSceneDiabetes",
    events: {
      load: ["loadGaitSceneDiabetes"],
      play: ["playGaitSceneDiabetes"],
    },
  },
  introScene: {
    name: "introScene",
    config: introConfig,
    domId: "intro-dom-id",
    region: 'us-east-1',
    events: {
      load: ["loadIntroScene"],
      play: ["playIntroScene"],
      sumerianplay: [""],
      sumerianLoad: [""]
    }
  }, //end introScene
  medSceneAsthma: {
    name: "medSceneAsthma",
    events: {
      load: ["loadMedSceneAsthma"],
      play: ["playMedSceneAsthma"],
      sumerianplay: [""],
      sumerianLoad: [""]
    }
  }, //end medScene
  medSceneDiabetes: {
    name: "medSceneDiabetes",
    events: {
      load: ["loadMedSceneDiabetes"],
      play: ["playMedSceneDiabetes"],
      sumerianplay: ["Diabetes_Meds"],
      sumerianLoad: ["Diabetes_Meds"]
    }
  }, //end medScene
  medSceneHeartDisease: {
    name: "medSceneHeartDisease",
    events: {
      load: ["loadMedSceneHeartDisease"],
      play: ["playMedSceneHeartDisease"],
      sumerianplay: [""],
      sumerianLoad: ""
    }
  }, //end medScene
  vitalsSceneAsthma: {
    name: "vitalsSceneAsthma",
    events: {
      load: ["loadVitalsSceneAsthma"],
      play: ["playVitalsSceneAsthma"],
      sumerianplay: [""],
      sumerianLoad: ""
    }
  }, //end vitalsScene
  vitalsSceneDiabetes: {
    name: "vitalsSceneDiabetes",
    events: {
      load: ["loadVitalsSceneDiabetes"],
      play: ["playVitalsSceneDiabetes"],
      sumerianplay: ["Diabetes_Vitals"],
      sumerianLoad: "Diabetes_Vitals"
    }
  }, //end vitalsScene
  vitalsSceneHeartDisease: {
    name: "vitalsSceneHeartDisease",
    events: {
      load: ["loadVitalsSceneHeartDisease"],
      play: ["playVitalsSceneHeartDisease"],
      sumerianplay: [""],
      sumerianLoad: [""]
    }
  }, //end vitalsScene
}; //end sceneConfigs

// configureAmplify();

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
/*
const awsmobile =  {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:ed992a09-1ae9-4a1e-888d-79fb78b5e7a0",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_KfP9NHfRI",
    aws_user_pools_web_client_id: "4sh0gk80psnb29sqp107khvp14",
    aws_content_delivery_bucket: "ces-demo-hosting",
    aws_content_delivery_bucket_region: "us-east-1",
    aws_content_delivery_url: "https://dilugtxt4s2ke.cloudfront.net",
    aws_appsync_graphqlEndpoint: "https://m4zwjpdqjrhilerlqfsaxgp4g4.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    // aws_mobile_analytics_app_id: "13e3d2101b0d45bf8b2b43ce9b9e81d6",
    // aws_mobile_analytics_app_region: "us-east-1"
};
*/

let config = {
	region: 'us-east-1', 
	userPoolId: 'us-east-1_KfP9NHfRI',
	userPoolWebClientId: '4sh0gk80psnb29sqp107khvp14', 
  identityPoolID: 'us-east-1:ed992a09-1ae9-4a1e-888d-79fb78b5e7a0', 
  //api config / credentials
  aws_appsync_graphqlEndpoint: 'https://m4zwjpdqjrhilerlqfsaxgp4g4.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
}; 

async function configureScenesObject(sceneConfigs) {
  let scenes = {};
  for (var key in sceneConfigs) {
    let sceneConfig = sceneConfigs[key];
    scenes[sceneConfig.name] = {
      sceneConfig: sceneConfig.config
    }
  }

  return scenes;
}

async function configureAmplify () {
  let AWS = require('aws-sdk');

   new AWS.IotData({endpoint: 'my.host.tld'});
   new AWS.Polly();
   new AWS.LexRuntime();

   await attachIotPolicy();

  let scenes = await configureScenesObject(sceneConfigs);

  console.log("in configAmpify, scenes: ", scenes);

  Amplify.configure({
      // ...awsmobile,
      XR:{
        SumerianProvider: { // Sumerian specific configuration
          region: 'us-east-1', // Sumerian region 
          scenes: scenes
        }      
       }//end XR
   }); //end Amplify.configure()

  // Amplify.configure(awsconfig);

 }; //end config()

async function loadAndStartScene() {
  try {
      await XR.loadScene(config.sceneName, config.sceneDivId);
     
      XR.start(config.sceneName);
    } catch (e) {
      console.log("Error in loadAndStartScene, error: ", e, e.stack);
    }
}  

/**
 * [attachIotPolicy description]
 * @return {[type]} [description]
 */
async function attachIotPolicy() {
    try {
        const policyName = 'ernest_health_iot_policy';
        const credentials = Auth.essentialCredentials();
        const target = credentials._identityId;

        const { policies } = await iot.listAttachedPolicies( { target } ).promise();

        if ( !policies.find( policy => policy.policyName === policyName ) ) {
            await iot.attachPolicy( { policyName, target } ).promise();
        }


    } catch ( e ) {
        // console.log(`error describing thing with thingName of ${gCameraName}, error: ${JSON.stringify(e, null, 2)}`);
        console.log( "Error stack", JSON.stringify( e.stack, null, 2 ) ); //original error message
    }
}

export default config;
export { configureAmplify, loadAndStartScene, attachIotPolicy }; 