import React, { Component } from 'react';
import Amplify, { Auth, PubSub, Hub, API, ServiceWorker, graphqlOperation } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { withAuthenticator } from 'aws-amplify-react';
import { configureAmplify } from './spaConfig';
import AmplifySceneLoader, { sceneConfigs } from './AmplifySceneLoader.js';
import { CFRSIGaitSceneUserInterface, AppScene, AppSceneSelectMenu, AppVideoContainer, VitalsUserInterface, CopingSceneUserInterface, GaitSceneUserInterfaceDiabetes, DiabetesMedSceneUserInterface, GaitSceneUserInterfaceHeartDisease, NutritionSceneUserInterface, AppButtons, AsthmaMedSceneUserInterface } from './containers';
import { AppSplashScreen, HelpTab, AppPendantScreen, AppHelpScreen, AppCustomerSupportScreen, /*LogIn,*/ AppBingo, AppCheckers, DevButtons, LoadingBackground } from './components';
import { getCookieInformation, attachIotPolicy } from './js/localUtils.js';
import gql from 'graphql-tag';

//NOTE: uncommenting this line breaks Sumerian scenes because it triggers some tree shaking that causes the AWS.Polly() (or IOT or whatever) is not a constructor error
//PLEASE DO NOT UNCOMMENT THIS LINE UNTIL WE"VE GOT TREESHAKING FIGURED OUT
// import AWSAppSyncClient, { AUTH_TYPE, buildSync } from 'aws-appsync';

import * as queries from './graphql/queries'; // eslint-disable-line no-unused-vars
import * as mutations from './graphql/mutations'; // eslint-disable-line no-unused-vars
import * as subscriptions from './graphql/subscriptions';
// import * as DeltaSync from "./graphql/DeltaSync";

import './addison.css';

var AWS = require('aws-sdk');


let serviceWorker = new ServiceWorker();
const registerWorker = async () => {
    serviceWorker = await serviceWorker.register('/src/service-worker.js', '/src/');
}


const awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:ed992a09-1ae9-4a1e-888d-79fb78b5e7a0",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_KfP9NHfRI",
    aws_user_pools_web_client_id: "4sh0gk80psnb29sqp107khvp14",
    aws_content_delivery_bucket: "ces-demo-hosting",
    aws_content_delivery_bucket_region: "us-east-1",
    aws_content_delivery_url: "https://dilugtxt4s2ke.cloudfront.net",
    API: {
        aws_appsync_graphqlEndpoint: "https://m4zwjpdqjrhilerlqfsaxgp4g4.appsync-api.us-east-1.amazonaws.com/graphql",
        aws_appsync_region: "us-east-1",
        aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    }
};


Amplify.configure(awsmobile);
Auth.configure({ authenticationFlowType: 'USER_PASSWORD_AUTH' })


Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint: 'wss://a27yhy3w1t33j3-ats.iot.us-east-1.amazonaws.com/mqtt',
}));

configureAmplify();
registerWorker();

// const client = new AWSAppSyncClient({
//   url: awsmobile.API.aws_appsync_graphqlEndpoint,
//   region: awsmobile.API.aws_appsync_region,
//   auth: {
//     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
//     //TODO:
//   }
// });

// client.hydrated().then(() =>
//   client.sync(
//     buildSync("User", {
//       baseQuery: {
//         query: DeltaSync.BaseQuery
//       },
//       subscriptionQuery: {
//         query: DeltaSync.Subscription
//       },
//       deltaQuery: {
//         query: DeltaSync.DeltaSync
//       },
//       cacheUpdates: ({ id }) => [
//         { query: DeltaSync.GetItem, variables: { id } }
//       ]
//     })
//   )
// );

class App extends Component {
    getInitialState() {
        return {
            headerTimeout: 5000, //1 seconds
            loading: true,
            authed: false,
            sumerianLoaded: false,
            authData: null,
            sceneController: null,
            medReminderUserInterfaceOn: true,
            copingUserInterfaceOn: true,
            gaitUserInterfaceDiabetesOn: true,
            gaitUserInterfaceHeartDiseaseOn: true,
            nutritionUserInterfaceOn: true,
            vitalsUserInterfaceOn: true,
            splashScreenOn: true,
            pendantScreenOn: true,
            helpScreenOn: false,
            customerSupportScreenOn: false,
            helpTabOn: false,
            appButtonsOn: true,
            eventData: undefined,
            currentScene: sceneConfigs.introScene,
            devMode: true,
            bingoOn: false,
            checkersOn: false,
            sceneSelectMenuOn: true,
            cfrsiGaitOn: false
        };
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.state.iot = new AWS.Iot();
        this.state.iotdata = new AWS.IotData({ endpoint: 'a27yhy3w1t33j3-ats.iot.us-east-1.amazonaws.com' });
        this.state.polly = new AWS.Polly();
        this.state.lexruntime = new AWS.LexRuntime();

        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.publishMessages = this.publishMessages.bind(this);
        this.sceneLoaded = this.sceneLoaded.bind(this);

        Hub.listen("sceneLoaded", this, "App");
        Hub.listen("IntroSceneLoaded", this, "App");
        Hub.listen("PlayingNewScene", this, "App");
        Hub.listen("ToggleBingo", this, "App");
        Hub.listen("ToggleCheckers", this, "App");
        Hub.listen("HideSceneSelectMenu", this, "App");
        Hub.listen("ToggleSceneSelectMenu", this, "App");
        Hub.listen("PlayCFRSI", this, "App");
        Hub.listen("StopCFRSI", this, "App");
        Hub.listen("ToggleCFRSI", this, "App");
    } //end ctor

    /**
     * [publishTestMessage description]
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    async publishTestMessage(id) {
        console.log("in publishTestMessages");
        var gCameraName = getCookieInformation('camName') //make this global for now

        PubSub.subscribe('TEST').subscribe({
            next: data => console.log("message received on TEST, ", data),
            error: err => console.log("err subscribing to TEST, err: ", err),
            close: () => console.log("done subbing to TEST")
        });

        //test to see if PubSub works
        await PubSub.publish('TEST', { msg: "Hi from Jeremy", qos: 1 });
    }

    /**
     * [attachIotPolicy description]
     * @return {[type]} [description]
     */
    async attachIotPolicy() {
        try {
            const policyName = 'ernest_health_iot_policy';
            const credentials = Auth.essentialCredentials();
            const target = credentials._identityId;

            const { policies } = await this.state.iot.listAttachedPolicies({ target }).promise();

            if (!policies.find(policy => policy.policyName === policyName)) {
                await this.state.iot.attachPolicy({ policyName, target }).promise();
            }
        } catch (e) {
            console.log("Error stack", JSON.stringify(e.stack, null, 2)); //original error message
        }
    }

    /**
     * [publishMessageByTopic description]
     * @param  {[type]} cmd [description]
     * @param  {[type]} id  [description]
     * @return {[type]}     [description]
     */
    async publishMessageByTopic(topic, cmd, id) {
        try {
            await this.attachIotPolicy();
            await PubSub.publish(topic, { msg: cmd + ":" + '" + id + "' });
        } catch (e) {
            console.log("Error stack", JSON.stringify(e.stack, null, 2)); //original error message
        }
    }
    /**
     * [publishMessages description]
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    async publishMessages(id) {
        await this.attachIotPolicy();
        console.log("in publishMessages");
        var gCameraName = getCookieInformation('camName') //make this global for now

        try {
            let result = await this.state.iot.describeThing({ thingName: gCameraName }).promise();
            console.log(`result of describeThing on ${gCameraName}: ${result}`);
        } catch (e) {
            console.log("Error stack", JSON.stringify(e.stack, null, 2)); //original error message
        }
    }

    /**
     * Publishes a command and message to the right topic.
     *
     * @param {String} topic        Topic to publish the command to.
     * @param {String} command      Command to send to the gait app.
     * @param {String} message      Message to accompany the command.
     */
    async publishMessage(topic, command, message) {
        return new Promise( async (resolve, reject) => {
            let publishParams = {
                topic: topic,
                payload: "{ \"[" + command + "]\" : " + message + " }",
                qos: 1
            };

            try {
                let publishData = await PubSub.publish(topic, { msg: "{ \"[" + command + "]\" : " + message + " }" });
                console.log("Successfully published message " + publishParams.payload + ' On topic ' + publishParams.topic);
                resolve(publishData);
            } catch (e) {
                await console.log("[!!!] Unable to publish message with payload: " + publishParams.payload + ' On topic ' + publishParams.topic + ' error: ' + e);
                reject(e);
            }
        })
    }

    async componentDidMount() {
        const userSubscription = API.graphql(
            graphqlOperation(subscriptions.onCreateUser)
        ).subscribe({
            next: (userData) => console.log("userData:", userData)
        });
        const readingSubscription = API.graphql(
            graphqlOperation(subscriptions.onCreateReading)
        ).subscribe({
            next: (readingData) => console.log("readingData:", readingData)
        });
        const eventSubscription = API.graphql(
            graphqlOperation(subscriptions.onCreateEventManagement)
        ).subscribe({
            next: (eventData) => {
                console.log("eventData: ", eventData)
                if (!(this.state.hasOwnProperty('eventData')) && ['medSceneAsthma', 'medSceneHeartDisease', 'vitalsSceneAsthma', 'vitalsSceneHeartDisease'].includes(this.state.currentSceneName)) {
                    Hub.dispatch("RapidEventReceived");
                    this.setState({ eventData: eventData });

                    setTimeout(() => this.setState({ eventData: null }), 60000) //start listening for events again after a minute, this is a hack
                }
            }
        });

        this.setState({
            authData: this.props.authData,
            userSubscription: this.state.userSubscription,
            readingSubscription: this.state.readingSubscription,
            eventSubscription: eventSubscription,
        });
    }



    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
        console.log(`in App.onHubCapsule, channel: ${channel}, payload: ${payload}`);

        if (channel === "ToggleBingo") {
            this.setState({ bingoOn: !(this.state.bingoOn) });
        } else if (channel === "ToggleCheckers") {
            this.setState({ checkersOn: !(this.state.checkersOn) });
        } else if (channel === "PlayCFRSI") {
            this.setState({ cfrsiGaitOn: true });
            window.amplifySceneLoader.dispatchPlayCFRSI();
        } else if (channel === "StopCFRSI") {
            this.setState({ cfrsiGaitOn: false });
        } else if (channel === "ToggleCFRSI") {
            this.setState({ cfrsiGaitOn: !(this.state.cfrsiGaitOn) });
        } else if (channel === "IntroSceneLoaded") {
            this.setState({ sceneSelectMenuOn: true });
        } else if (channel === "ToggleSceneSelectMenu") {
            this.setState({ sceneSelectMenuOn: !(this.state.sceneSelectMenuOn) });
        } else if (channel === "HideSceneSelectMenu") {
            this.setState({ devMode: false });
            this.setState({ sceneSelectMenuOn: false });
        } else if (channel === "PlayingNewScene") {
            // console.assert( payload.hasOwnProperty('name') ); //basic testing
            let sceneConfig = payload;

            if (sceneConfig && sceneConfig.hasOwnProperty('name')) console.log(`New scene to play: ${sceneConfig.name} `);

            //yeah, i know, thanks
            this.setState({ devMode: false });
            this.setState({ bingoOn: false });
            this.setState({ sceneSelectMenuOn: false });
            this.setState({ prevScene: this.state.currentScene });
            this.setState({ currentScene: sceneConfig, currentSceneName: sceneConfig.name });
        }
    }

    sceneLoaded(sceneController) {
        this.setState({
            loading: false,
            sceneController
        });
        this.publishMessages("jkeys");

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
        window.amplifySceneLoader.putSceneController(sceneController);
        window.sceneController = sceneController;

        window.amplifySceneLoader.emit("TokensGenerated", this.props.authData);

        //add listeners for all emits from Sumerian in order to rebroadcast them as Hub events
        window.amplifySceneLoader.initHubDispatchFromSumerianEmit();
        window.amplifySceneLoader.initSceneSwitchCallbacks();

        console.log(`sceneController: ${sceneController}`); 
        setTimeout(() => Hub.dispatch("IntroSceneLoaded"), 1500); //wait a couple of seconds to let addison pop in gracefully
    }

    render() {
        return (
            <div className="App">
        {/*<div style={{height:"465px",width:"320px",zIndex:"200",position:"absolute"}} id={"containerDiv"}></div>*/}
        <AppButtons on={this.state.appButtonsOn}/>
        <DevButtons on={this.state.devMode} /> 

        <AppSceneSelectMenu on={this.state.sceneSelectMenuOn} />
        <LoadingBackground />

        <AsthmaMedSceneUserInterface/>
        <AppVideoContainer />
        <HelpTab on={this.state.helpTabOn} />
        <AppPendantScreen on={this.state.pendantScreenOn} />
        <AppHelpScreen on={this.state.helpScreenOn} />
        <AppCustomerSupportScreen on={this.state.customerSupportScreenOn} />
        <AppSplashScreen />

        <AppBingo on={this.state.bingoOn} />
        <AppCheckers on={this.state.checkersOn} />

        <VitalsUserInterface on={this.state.vitalsUserInterfaceOn} /> 

        <DiabetesMedSceneUserInterface />

        {/*<MedReminderUserInterface on={this.state.medReminderUserInterfaceOn} />*/}
        <CopingSceneUserInterface on={this.state.copingUserInterfaceOn} />
        <GaitSceneUserInterfaceDiabetes on={this.state.gaitUserInterfaceDiabetesOn} />
        <GaitSceneUserInterfaceHeartDisease on={this.state.gaitUserInterfaceHeartDiseaseOn} />
        <NutritionSceneUserInterface on={this.state.nutritionUserInterfaceOn} />

        <CFRSIGaitSceneUserInterface on={this.state.cfrsiGaitOn} />

        <AppScene sceneConfig={sceneConfigs.introScene} play={true} load={true} onLoaded={(controller) => this.sceneLoaded(controller)}/>
      </div>
        );
    }
}

// export default withAuthenticator(App, false, [
//   <LogIn/>,
//   <ConfirmSignIn/>,
//   <VerifyContact/>,
//   <SignUp/>,
//   <ConfirmSignUp/>,
//   <ForgotPassword/>,
//   <RequireNewPassword />
// ]);

export default withAuthenticator(App);