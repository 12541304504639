import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class HeartDiseaseStopSmoke1 extends Component {
	getInitialState() {
		return {
			on: false,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("showStopSmoking1", this);
        Hub.listen("showStopSmoking2", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.reset();
        }
        else if(channel === "showStopSmoking1") {
            this.show(); //pull up smoking tab
        }
        else if(channel === "showStopSmoking2") {
            this.hide(); //changes inner html of stop smoking tab
        }
    }

//emit: "HD_StopSmoking_TextChange"
    render() {
        return (

            <button className={this.state.on ? "btnLarge" : "hidden"} id="btnready">
                &nbsp;Ready&nbsp;    //emit: "post_to_lex", "start stop smoking"        
            </button>

        );
    }
}