import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class BTN_Done extends Component {
  getInitialState() {
    return {
      on: false
    }
  }

constructor( props ) {
  super( props );
  this.state = this.getInitialState();

  this.getInitialState = this.getInitialState.bind(this);
  this.onHubCapsule = this.onHubCapsule.bind(this);
  this.handleClick = this.handleClick.bind(this);
  this.reset = this.reset.bind(this);
  this.show = this.show.bind(this);
  this.hide = this.hide.bind(this);

  Hub.listen("ShowReady", this);
  Hub.listen("HideReady", this);
  Hub.listen("readybtn", this);
  Hub.listen("toggleReady", this);
  Hub.listen("ListDone", this);

  Hub.listen("GlucoseIntro", this);
  Hub.listen("WeightIntro", this);
  Hub.listen("TempIntro", this);
  Hub.listen("GlucoseTutorial", this);
  Hub.listen("GlucoseTutorial1", this);
  Hub.listen("GlucoseTutorial2", this);
  Hub.listen("WeightTutorial", this);
  Hub.listen("UIReset", this);

  Hub.listen("DoneVisibility", this);
  Hub.listen("btndone", this);

  window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
}

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  show() {
    this.setState({on: true});
  }


  hide() {
    this.setState({on: false});
  }

  reset() {
    this.setState(this.getInitialState());
  }

  onHubCapsule( capsule ) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    if(channel === "UIReset") {
      this.reset();
    }
    else if(channel === "ListDone"){
      this.show();
    }
    else if(channel === "btndone"){
      this.hide();
    }
    else if(channel === "DoneVisibility"){
      this.show();
    }
  }

  handleClick(event) {
    event.preventDefault();
    this.hide();
    window.amplifySceneLoader.emit("post_to_lex", "Done");
  }


  render() {
    return (
      <button className={this.state.on ? "btndone" : "hidden"} onClick={this.handleClick} id="btndone">


        <svg
          x="0px"
          y="0px"
          viewBox="0 0 497.7 497.7"
          width="5vw"
          height="5vw"
          overflow="visible"
        >
          <g transform="translate(-50,100)">
            <path
              className={this.state.on ? "white" : "hidden"}
              fill="#d2d6e8"
              d="M63.95,0c-15.7,0-28.4,12.7-28.4,28.4v440.9c0,15.7,12.7,28.4,28.4,28.4   s28.4-12.7,28.4-28.4V28.5C92.45,12.8,79.65,0,63.95,0z"
            />

            <path
              className={this.state.on ? "white" : "hidden"}
              fill="#d2d6e8"
              d="M63.95,0c-15.7,0-28.4,12.7-28.4,28.4v440.9c0,3.9,3.2,7.1,7.1,7.1   s7.1-3.2,7.1-7.1V48.6c0-11.1,9-20.1,20.1-20.1h22.6C92.45,12.8,79.65,0,63.95,0z"
            />

            <path
              className={this.state.on ? "white" : "hidden"}
              fill="#d2d6e8"
              d="M453.65,29.6c-23.7-10.2-48.9-15.3-76.7-15.8l-19.6,5.4l-65.8,9.2l-14.2-1.8  c-3.4,0.7-6.8,1.4-10.1,2.1c-30.8,6.7-60.2,12.7-89.4,14l-14.2,14.2l-85.5-14.2v270.2h85.3l14.2,28.4h1.1  c31.9-1.4,63.6-7.9,94.3-14.5c1.4-0.3,2.7-0.6,4.1-0.9l9.2-9.2l77.3-10.1l13,5.9c23.9,0.5,45.4,4.8,65.5,13.4  c4.4,1.9,9.5,1.5,13.4-1.2c4-2.6,6.4-7.1,6.4-11.9V42.7C462.15,37,458.85,31.9,453.65,29.6z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M177.75,42.7c-3.4,0.1-6.8,0.5-10.2,0.5c-25.6,0-48.3-4.5-69.5-13.6c-4.4-1.9-9.5-1.5-13.4,1.2   c-4,2.6-6.4,7.1-6.4,11.9V128c0,0,46.8,14.2,99.6,14.2V42.7H177.75z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M376.85,13.9c-1.4,0-2.6-0.2-4-0.2c-32.3,0-64.3,6.3-95.5,13v101.4c0,0,30.8-14.2,99.6-14.2v-100   H376.85z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M177.75,142.2v99.6c42.7,0,99.6-14.2,99.6-14.2V128C277.25,128,223.95,142.2,177.75,142.2z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M376.85,213.4c38.1,0,68.6,8,85.3,13.6V126.7c-16.3-5.4-46-12.9-85.3-12.9V213.4z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M78.15,312.9c0,5.7,3.4,10.8,8.6,13.1c21.3,9.2,43.9,14.1,68.6,15.4h22.4v-99.6   c-59.2,0-99.6-14.2-99.6-14.2S78.15,312.9,78.15,312.9z"
            />
            <path
              className={this.state.on ? "purple" : "hidden"}
              fill="#9944BB"
              d="M277.25,325.9c32.9-7.1,64.2-13.6,95.5-13.6c1.4,0,2.7,0.2,4,0.2v-99.2c-64,0-99.6,14.2-99.6,14.2   v98.4H277.25z"
            />
          </g>
        </svg>

        &nbsp;Done&nbsp;

      </button>
    );
  }
}
