import React, { Component } from 'react';
import { Hub } from 'aws-amplify'; //eslint-disable-line no-unused-vars

import './css/CSS_MicDot.css';

export default class MicDotButton extends Component {
	getInitialState() {
		return {
			on: true,
            isRecording: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
        this.toggleMicrophone = this.toggleMicrophone.bind(this);
    }

    toggleMicrophone() {
        this.setState({isRecording: !this.state.isRecording});

        window.amplifySceneLoader.emit("ToggleMicrophone" , this.state.isRecording );

        if(this.state.isRecording) {
            // window.amplifySceneLoader.sceneController.
        }
    }

    componentDidMount() {
        if(this.state.isRecording) {

        }

        document.getElementById("micDot").addEventListener("click", function(){
          this.classList.toggle("micDotOff");
        })
        document.getElementById("micDot").addEventListener("touchstart", function(){
          this.classList.toggle("micDotOff");
        })

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }

    handleClick(event) {
        // let micDot = this.refs.micDot;
    }


    render() {
        return this.state.on
        ? ( <button className={this.state.isRecording ? "micDotOn" : "micDotOff"} onClick={this.toggleMicrophone} id="micDot" ref="micDot"></button>)
        : (null);
    }
}
