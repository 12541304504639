import React, { Component } from 'react';
import { XR, Hub } from 'aws-amplify';

export default class GaitAppUIRestart extends Component {
	getInitialState() {
		return {
			on: true
		}
	}
 
    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("ToggleThisComponent", this);
        Hub.listen("ShowThisComponent", this);
        Hub.listen("HideThisComponent", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }

    hide() {
      this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    }


    render() {
        // if(!(this.state.on)) return (null);

        return (
            <div className={this.state.on ? "helpDiv" : "hidden"} id="helpForward">
              
            <table>
              <tr>
                <td><button className="restartButton" id="nextButton" type="submit" value="Next">Next Tutorial</button></td>
                </tr>
            </table>

            </div>		
        );
    }
}