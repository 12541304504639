import React from 'react';
import { Hub } from 'aws-amplify'
import { PollenTab, MedicationChartTab , SnoozeTimer , NextMedTab, AlertTab, GreatJobTab} from '../components';


export default class AsthmaMedReminderUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        //this.state.on = this.props.on;

        Hub.listen("playMedSceneAsthma", this);
        Hub.listen("toggleAsthmaMedSceneUserInterface", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "playMedSceneAsthma") {
            this.setState({on: true});
        }
    }

	render() {
      return (
        <div className={this.state.on ? "" : "hidden"}>
        <PollenTab />
        <MedicationChartTab/>
        <SnoozeTimer/>
        <NextMedTab/>
        <AlertTab/>
        <GreatJobTab/>
		</div>
	  );
	}
}
