import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AWS from 'aws-sdk';

function saveAll() {
    LexSubmit( 'Yes' );
}

function sendReady() {
    LexSubmit( 'Ready' )
}


var lexParams = {
    botAlias: 'Dev_Alias',          /* required */
    botName: 'EH_Gait_Bot',         /* required */
    userId: 'testUser' + Date.now(),    /* required */
    accept: 'text/plain; charset=utf-8',
    contentType: 'text/plain; charset=utf-8',
    sessionAttributes: {},
    requestAttributes: {}
};

function LexSubmit( input ) {
    if ( input ) {

        lexParams.inputStream = input.replace( '-', '' )
            .trim();
        //console.log("Sending Parameters >> " + JSON.stringify(lexParams));
        let lexruntime = new AWS.LexRuntime();
        console.log( lexParams )
        lexruntime.postContent( lexParams, (response) => {console.log(response)} );
    }
}



export default class SaveAllButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("ShowSaveAllButton", this);
        Hub.listen("HideSaveAllButton", this);
        Hub.listen("showSaveAllButton", this);
        Hub.listen("hideSaveAllButton", this);
        Hub.listen("toggleSaveAllBtn", this);
        Hub.listen("SceneReset", this);
        Hub.listen("UIReset", this);
        Hub.listen("Begin", this);

        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
    }



    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      Hub.dispatch("SaveAllClicked");
      // saveAll();
      window.amplifySceneLoader.emit("post_to_lex", "Yes");

      if(true) {
        Hub.dispatch("SaveAllSuccessful");
      } else {
        Hub.dispatch("SaveAllFailed");
      }
    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "ShowSaveAllButton" || channel === "showSaveAllButton") {
        	this.setState({on: true});
        }
        
        else if(channel === "hideSaveAllButton" || channel === "HideSaveAllButton") {
        	this.setState({on: false});
        }
        
        else if(channel === "toggleSaveAllBtn") {
        	this.setState({on: !(this.state.on)});
        }
    }

    render() {
        if(!(this.state.on)) return (null);

        const buttonStyle = {
            position: "absolute",
            left: "20vw",
            top: "90vh",
            zIndex: "50000"
        };

        return (
         <button style={buttonStyle} type='button' id='saveBtn' className='ui-button dev-button' onClick={this.handleClick}>Save All</button>

        );
    }
}