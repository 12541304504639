import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class NextMedTab extends Component {
	getInitialState() {
		return {
			on: false,
            MedDate: "Tomorrow",
            MedTime: "08:00 am",
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("MissedMedList", this);
        Hub.listen("showAlertTab", this);
        Hub.listen("NextMedList", this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.populateNextMed = this.populateNextMed.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);


    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
           this.reset();
        }

        else if(channel === "MissedMedList") {
           this.show();
        }

        else if(channel === "showAlertTab") {
          this.hide();
      } else if (channel === "NextMedList"){
          this.populateNextMed(payload);
      }

    }


    render() {
        return (

            <div id="NextBackground" className={this.state.on ? "NextBackground" : "hidden"}>

                <div id="NextTitle" className={this.state.on ? "NextTitle" : "hidden"}>

                    <svg
                        viewBox="0 0 429.294 429.294"
                        width="4.5vw"
                        height="4.5vw"
                        overflow="visible">

                            <g transform="translate(400,120) scale(-1, 1)">
                                <path
                                    style={{fill:"#EEF0F6"}}
                                    d="M289.369,318.437l-67.365-67.365l-93.274,93.274l67.365,67.365l0.043-0.043     c25.866,24.045,66.326,23.494,91.504-1.684s25.729-65.638,1.684-91.503L289.369,318.437z"
                                    className={this.state.on ? "Pill_1_White" : "hidden"}/>

                                <path
                                    style={{fill:"#B4443E"}}
                                    d="M154.639,183.706l-0.043,0.043c-25.866-24.045-66.326-23.494-91.504,1.684     c-25.178,25.177-25.729,65.638-1.684,91.504l-0.043,0.043l67.366,67.365l93.274-93.274L154.639,183.706z"
                                    className={this.state.on ? "Pill_1_Red" : "hidden"}/>

                                <path
                                    style={{fill:"#EEF0F6"}}
                                    d="M233.199,17.583l-0.043,0.043C207.29-6.419,166.83-5.868,141.652,19.31     s-25.729,65.638-1.684,91.504l-0.043,0.043l67.365,67.365l93.274-93.275L233.199,17.583z"
                                    className={this.state.on ? "Pill_2_White" : "hidden"}/>

                                <path
                                    style={{fill:"#B4443E"}}
                                    d="M367.886,152.356l0.044-0.044l-67.366-67.365l-93.274,93.275l67.365,67.366l0.043-0.044     c25.866,24.045,66.326,23.494,91.504-1.684C391.38,218.682,391.931,178.222,367.886,152.356z"
                                    className={this.state.on ? "Pill_2_Red" : "hidden"}/>
                            </g>

                    </svg>


                Next Medication

                    <svg
                        viewBox="0 0 429.294 429.294"
                        width="4.5vw"
                        height="4.5vw"
                        overflow="visible">
                            <g transform="translate(0,120) scale(1, 1)">
                                <path
                                    style={{fill:"#EEF0F6"}}
                                    d="M289.369,318.437l-67.365-67.365l-93.274,93.274l67.365,67.365l0.043-0.043     c25.866,24.045,66.326,23.494,91.504-1.684s25.729-65.638,1.684-91.503L289.369,318.437z"
                                    className={this.state.on ? "Pill_1_White" : "hidden"}/>

                                <path
                                    style={{fill:"#B4443E"}}
                                    d="M154.639,183.706l-0.043,0.043c-25.866-24.045-66.326-23.494-91.504,1.684     c-25.178,25.177-25.729,65.638-1.684,91.504l-0.043,0.043l67.366,67.365l93.274-93.274L154.639,183.706z"
                                    className={this.state.on ? "Pill_1_Red" : "hidden"}/>

                                <path
                                    style={{fill:"#EEF0F6"}}
                                    d="M233.199,17.583l-0.043,0.043C207.29-6.419,166.83-5.868,141.652,19.31     s-25.729,65.638-1.684,91.504l-0.043,0.043l67.365,67.365l93.274-93.275L233.199,17.583z"
                                    className={this.state.on ? "Pill_2_White" : "hidden"}/>

                                <path
                                    style={{fill:"#B4443E"}}
                                    d="M367.886,152.356l0.044-0.044l-67.366-67.365l-93.274,93.275l67.365,67.366l0.043-0.044     c25.866,24.045,66.326,23.494,91.504-1.684C391.38,218.682,391.931,178.222,367.886,152.356z"
                                    className={this.state.on ? "Pill_2_Red" : "hidden"}/>
                            </g>

                    </svg>
              </div>
            <div id="YourNextMed" className={this.state.on ? "YourNextMed" : "hidden"}>
              Your next reminder is
              <div id="NextMedDate" className={this.state.on ? "NextMedDate" : "hidden"}>{this.state.MedDate}</div>
              at
              <div id="NextMedTime" className={this.state.on ? "NextMedTime" : "hidden"}>{this.state.MedTime}</div>
              </div>

             </div>



        );
    }

    populateNextMed(data){
        this.setState({
            MedDate: data.NextMedDate,
            MedTime: data.NextMedTime,
        })
    }
}
