

import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class WeightTutorialImage extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        Hub.listen("WeightTutorial", this);
        Hub.listen("WeightTaken", this);
        Hub.listen("UIReset", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    show() {
    	this.setState({on: true});
    }

    hide() {
    	this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
        }

        else if(channel === "WeightTutorial") {
            this.setState({on: true});
        } 

        else if(channel === "WeightTaken") {
            this.setState({on: false});
        }
    }


    render() {
            return (
                <img className={this.state.on ? "vitalsTutorialImage" : "hidden"} alt="Person demonstrating how to use Addison Care scale" src="https://s3.amazonaws.com/addison-project-anim-files/UI+Images/Weight+1.jpg"/>
		);
	}
}