import React, {Component} from 'react';
import { Hub } from 'aws-amplify'; // eslint-disable-line no-unused-vars

import { AddisonButton, HelpButton, /*GotoButton,*/ SnoozeButton, YesButton, NoButton, DoneButton, MicDotButton, SaveAllButton, } from '../components';


export default class AppButtons extends Component {
	getInitialState() {
		return {
			on: true,
			debug: true,
			saveAllButtonOn: false,
			snoozeButtonOn: false,
			yesButtonOn: false,
			noButtonOn: false,
			helpButtonOn: true,
			addisonButtonOn: true,
			micDotButtonOn: true,
			doneButtonOn: false,
		}
	}

    constructor( props ) {
        super( props );
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

		this.state = this.getInitialState();
        
        //SaveAllButton.jsx
        Hub.listen("ShowSaveAllButton", this, "AppButtons");
        Hub.listen("HideSaveAllButton", this, "AppButtons");

        //BTN_Yes.jsx
        Hub.listen("UIReset", this, "AppButtons");
        Hub.listen("ShowYes", this, "AppButtons");
        Hub.listen("HideYes", this, "AppButtons");
        Hub.listen("toggleYes", this, "AppButtons");
        Hub.listen("sYN", this, "AppButtons");
        Hub.listen("hYN", this, "AppButtons");
        Hub.listen("snooze", this, "AppButtons");
        Hub.listen("snooze_last", this, "AppButtons");
        Hub.listen("YesBtn", this, "AppButtons");
        Hub.listen("NoBtn", this, "AppButtons");
        Hub.listen("yes", this, "AppButtons");
        Hub.listen("no", this, "AppButtons");
        Hub.listen("not yet", this, "AppButtons");

        //BTN_No.jsx
        Hub.listen("UIReset", this, "AppButtons");
        Hub.listen("ShowNo", this, "AppButtons");
        Hub.listen("HideNo", this, "AppButtons");
        Hub.listen("toggleNo", this, "AppButtons");
        Hub.listen("sYN", this, "AppButtons");
        Hub.listen("hYN", this, "AppButtons");
        Hub.listen("YesBtn", this, "AppButtons");
        Hub.listen("NoBtn", this, "AppButtons");
        Hub.listen("no", this, "AppButtons");
        Hub.listen("yes", this, "AppButtons");
    }

	componentDidMount() { }

	componentDidUpdate() { }

    show() {
      this.setState({on: true});
    }

    hide() {
      this.setState({on: false});
    }

    reset() {
        this.setState(this.getInitialState());
    }

	onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars


	}


	render() {
		return (
	      <div className={this.state.on ? "" : "hidden"}>
	        <AddisonButton on={this.state.addisonButtonOn} />
	        <HelpButton on={this.state.helpButtonOn} />
	        <SnoozeButton on={this.state.snoozeButtonOn} />
	        <YesButton on={this.state.yesButtonOn} />
	        <NoButton on={this.state.noButtonOn} />
	        <DoneButton on={this.state.doneButtonOn} />
	        <MicDotButton on={this.state.micDotButtonOn} />
	      </div>
		);
	}
}
