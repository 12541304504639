import React, { Component } from 'react';
// import { Hub } from 'aws-amplify';
// import AmplifySceneLoader from '../AmplifySceneLoader';

// import { PulseOxTutorialImageOne, PulseOxTutorialImageTwo, BloodGlucoseTutorialImageOne, BloodGlucoseTutorialImageTwo, WeightTutorialImage } from '../components';

// window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();

export default class AppImageContainer extends Component {
	getInitialState() {
		return {
			on: false,
		}
	}

	constructor(props) {
		super(props);
		this.state = this.getInitialState();

		this.state.on = this.props.on || this.state.on;

	}

	onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

		if(channel === "UIReset") {
			this.setState(this.getInitialState());
		}

	}

	render() {
		return (

			<div className={this.state.on ? "vitalsTutorialImage" : "hidden"} >
			

			</div>
		)
	}


}