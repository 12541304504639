//DiabetesVitalsInterface.jsx

import React from 'react';
import { Hub } from 'aws-amplify'
import { WeightTutorialImage, BloodGlucoseTutorialImageOne, BloodGlucoseTutorialImageTwo, DiabetesTab, DiabetesSmokingTab, ReadyAddisonButton  } from '../components';


export default class DiabetesVitalsUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on || this.state.on;

        Hub.listen("playVitalsSceneDiabetes", this);
        Hub.listen("toggleDiabetesVitalsUserInterface", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "playVitalsSceneDiabetes") {
            this.setState({on: true});
        }
    }

	render() {
      return (
        <div className={this.state.on ? "absolute" : "hidden"}>
            <BloodGlucoseTutorialImageOne />
            <BloodGlucoseTutorialImageTwo />
            <WeightTutorialImage />
            <DiabetesTab />
            <DiabetesSmokingTab />
            <ReadyAddisonButton />
		</div>
	  );
	}
}
