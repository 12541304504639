import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
 
export default class DevButtons extends Component {
    getInitialState() {
        return {
        }
    }

    constructor( props ) {
        super( props );

        console.log("in DevButtons ctor, props:", props);

        this.state = this.getInitialState();
        this.toggleBingo = this.toggleBingo.bind(this);
        this.toggleCheckers = this.toggleCheckers.bind(this);

        Hub.listen("asdf", this);
    }

    componentDidMount() { }

    componentDidUpdate() { }

    toggleBingo() {
        Hub.dispatch("ToggleBingo");
        Hub.dispatch("ToggleSceneSelectMenu");
    }

    toggleCheckers() {
        Hub.dispatch("ToggleCheckers");
        Hub.dispatch("ToggleSceneSelectMenu");
    }

    toggleMenu() {
        Hub.dispatch("ToggleSceneSelectMenu"); 
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "asdasdasd") {
            this.setState({on: true});
        } 
    }

    render() {
        return this.props.on ? (  
            <div style={{zIndex:"90000000"}} className="vertical-container ui-button">
                    <button onClick={() => this.toggleMenu()} className="vertical-item btn-ecg">
                        Menu
                    </button>
                    <button onClick={() => {Hub.dispatch("PlayCFRSI")}} className="vertical-item btn-ecg">                    
                        CFRSI Scene
                    </button>
                    <button onClick={() => document.location.assign("https://us-east-1.sumerian.aws/3432602847b24f5399b5b3c5070c08ff.scene") } className="vertical-item btn-ecg">                    
                        Gait
                    </button>
                    <button onClick={this.toggleBingo} className="vertical-item btn-ecg">
                        Bingo
                    </button>
                    <button onClick={this.toggleCheckers} className="vertical-item btn-ecg">
                        Checkers
                    </button>
            </div>
        ) : (null);
    }
}