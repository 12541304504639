import React, {Component} from 'react';
// import { Hub } from 'aws-amplify';


export default class AppCustomerSupportScreen extends Component {
  getInitialState() {
    return {
      on: false
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onHubCapsule = this.onHubCapsule.bind(this);
  } //end ctor  


  componentDidMount() {
  }

  componentDidUpdate() {
  }

  toggle() {
    this.setState({on: !this.state.on }); //toggle 
  }

  hide() {
    this.setState({on: false});
  }

  show() {
    this.setState({on: true});
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    
    if(channel === "") {

    }

  }


	render() {
		return (
    <div className={this.state.on ? "helpScreenBackground" : "hidden"} id="helpScreenBackground">
        <div className="helpScreenTitle" id="customerSupportTitle">
            {/*<?xml version="1.0"?>*/} 
            <svg id="gearIcon" width="5vw" height="5vw" viewBox="0 0 896.025 896.025" overflow="visible">
                <g transform="translate(-200,0)">
                    <g>
                        <path id="settings_1_" d="M863.24,382.771l-88.759-14.807c-6.451-26.374-15.857-51.585-28.107-75.099l56.821-70.452   c12.085-14.889,11.536-36.312-1.205-50.682l-35.301-39.729c-12.796-14.355-34.016-17.391-50.202-7.165l-75.906,47.716   c-33.386-23.326-71.204-40.551-112-50.546l-14.85-89.235c-3.116-18.895-19.467-32.759-38.661-32.759h-53.198   c-19.155,0-35.561,13.864-38.608,32.759l-14.931,89.263c-33.729,8.258-65.353,21.588-94.213,39.144l-72.188-51.518   c-15.558-11.115-36.927-9.377-50.504,4.171l-37.583,37.61c-13.548,13.577-15.286,34.946-4.142,50.504l51.638,72.326   c-17.391,28.642-30.584,60.086-38.841,93.515l-89.743,14.985C13.891,385.888,0,402.24,0,421.435v53.156   c0,19.193,13.891,35.547,32.757,38.663l89.743,14.985c6.781,27.508,16.625,53.784,29.709,78.147L95.647,676.44   c-12.044,14.875-11.538,36.312,1.203,50.669l35.274,39.73c12.797,14.382,34.028,17.363,50.216,7.163l77-48.37   c32.581,22.285,69.44,38.664,108.993,48.37l14.931,89.25c3.048,18.896,19.453,32.76,38.608,32.76h53.198   c19.194,0,35.545-13.863,38.661-32.759l14.875-89.25c33.308-8.147,64.531-21.245,93.134-38.5l75.196,53.705   c15.53,11.155,36.915,9.405,50.478-4.186l37.598-37.597c13.532-13.536,15.365-34.893,4.127-50.479l-53.536-75.059   c17.441-28.738,30.704-60.238,38.909-93.816l88.758-14.82c18.921-3.116,32.756-19.469,32.756-38.663v-53.156   C895.998,402.24,882.163,385.888,863.24,382.771z M449.42,616.013c-92.764,0-168-75.25-168-168c0-92.764,75.236-168,168-168   c92.748,0,167.998,75.236,167.998,168C617.418,540.763,542.168,616.013,449.42,616.013z" fill="#3A4456" />
                    </g>
                </g>
            </svg>
            Attention
            {/*<?xml version="1.0"?>*/} 
            <svg id="gearIcon" width="5vw" height="5vw" viewBox="0 0 896.025 896.025" overflow="visible">
                <g transform="translate(200,0)">
                    <g>
                        <path id="settings_1_" d="M863.24,382.771l-88.759-14.807c-6.451-26.374-15.857-51.585-28.107-75.099l56.821-70.452   c12.085-14.889,11.536-36.312-1.205-50.682l-35.301-39.729c-12.796-14.355-34.016-17.391-50.202-7.165l-75.906,47.716   c-33.386-23.326-71.204-40.551-112-50.546l-14.85-89.235c-3.116-18.895-19.467-32.759-38.661-32.759h-53.198   c-19.155,0-35.561,13.864-38.608,32.759l-14.931,89.263c-33.729,8.258-65.353,21.588-94.213,39.144l-72.188-51.518   c-15.558-11.115-36.927-9.377-50.504,4.171l-37.583,37.61c-13.548,13.577-15.286,34.946-4.142,50.504l51.638,72.326   c-17.391,28.642-30.584,60.086-38.841,93.515l-89.743,14.985C13.891,385.888,0,402.24,0,421.435v53.156   c0,19.193,13.891,35.547,32.757,38.663l89.743,14.985c6.781,27.508,16.625,53.784,29.709,78.147L95.647,676.44   c-12.044,14.875-11.538,36.312,1.203,50.669l35.274,39.73c12.797,14.382,34.028,17.363,50.216,7.163l77-48.37   c32.581,22.285,69.44,38.664,108.993,48.37l14.931,89.25c3.048,18.896,19.453,32.76,38.608,32.76h53.198   c19.194,0,35.545-13.863,38.661-32.759l14.875-89.25c33.308-8.147,64.531-21.245,93.134-38.5l75.196,53.705   c15.53,11.155,36.915,9.405,50.478-4.186l37.598-37.597c13.532-13.536,15.365-34.893,4.127-50.479l-53.536-75.059   c17.441-28.738,30.704-60.238,38.909-93.816l88.758-14.82c18.921-3.116,32.756-19.469,32.756-38.663v-53.156   C895.998,402.24,882.163,385.888,863.24,382.771z M449.42,616.013c-92.764,0-168-75.25-168-168c0-92.764,75.236-168,168-168   c92.748,0,167.998,75.236,167.998,168C617.418,540.763,542.168,616.013,449.42,616.013z" fill="#3A4456" />
                    </g>
                </g>
            </svg>
        </div>
        <div className="helpScreenText" id="helpScreenText">
            Connecting you to
            <br/>
            <div id="helpIconLarge">
                <svg x="0px" y="0px" viewBox="0 0 512 512" width="23vw" height="23vw" overflow="visible">
                    <g transform="translate (0,0)" className="white">
                        <path className="white" fill="#d2d6e8" d="M423.683,315.569c0.052,7.011-4.099,13.325-10.46,16.275c-40.022,18.564-95.66,30.088-157.224,30.088  s-117.202-11.523-157.225-30.088c-6.36-2.951-10.511-9.265-10.46-16.275C89.57,147.062,120.395,0,256,0  S422.43,147.062,423.683,315.569z" />
                        <path className="customerSupportFill" fill="#0098C6" d="M317.793,342.844v-42.706H194.207v42.706c0,11.399-7.294,21.519-18.109,25.123l-84.126,28.042  c-18.023,6.008-30.179,22.875-30.179,41.873v56.463c0,9.751,7.904,17.655,17.655,17.655h353.103c9.751,0,17.655-7.904,17.655-17.655  v-56.463c0-18.998-12.157-35.865-30.18-41.873l-84.126-28.042C325.087,364.362,317.793,354.242,317.793,342.844z" />
                        <path className="customerSupportFill" fill="#0098C6" d="M194.207,342.844c0,10.685-6.453,20.173-16.161,24.298c98.92,16.858,139.72-53.762,139.72-53.762  l0.028-0.019v-13.223H194.207V342.844z" />
                        <path className="customerSupportFill" fill="#0098C6" d="M142.72,185.469l-10.251-0.033c-9.772-0.031-17.711,7.883-17.711,17.655v34.899  c0,9.888,8.12,17.848,18.007,17.652l15.215-0.303L142.72,185.469z" />
                        <path className="customerSupportFill" fill="#0098C6" d="M369.28,185.469l10.251-0.033c9.772-0.031,17.711,7.883,17.711,17.655v34.899  c0,9.888-8.12,17.848-18.007,17.652l-15.215-0.303L369.28,185.469z" />
                        <path className="customerSupportFill" fill="#0098C6" d="M141.241,105.931v38.061c0,15.816,0.607,31.625,1.82,47.393l5.087,66.129  c1.216,15.807,9.448,30.238,22.435,39.329l53.308,37.315c9.407,6.585,20.612,10.118,32.095,10.118h0.026  c11.482,0,22.688-3.532,32.095-10.118l53.308-37.315c12.988-9.091,21.22-23.522,22.435-39.329l5.087-66.129  c1.213-15.768,1.82-31.578,1.82-47.393v-38.061H141.241z" />
                        <path className="white" fill="#d2d6e8" d="M420.026,396.009l-68.74-22.913c-32.717,44.385-70.904,69.187-95.286,77.111  c-24.382-7.924-62.569-32.726-95.286-77.111l-68.74,22.913c-18.024,6.008-30.18,22.875-30.18,41.873v56.463  c0,9.75,7.905,17.655,17.655,17.655h353.103c9.75,0,17.655-7.905,17.655-17.655v-56.463  C450.207,418.883,438.05,402.017,420.026,396.009z" />
                        <path className="white" fill="#d2d6e8" d="M132.414,97.103v61.793c104.585,0,184.198-36.41,197.532-44.455  c14.33,35.627,40.151,44.455,40.151,44.455h9.49V97.103H132.414z" />
                        <path className="white" fill="#d2d6e8" d="M379.234,255.641l-15.147-0.301c-6.74,12.245-26.929,32.295-87.123,32.295v17.655  c80.439,0,101.08-34.278,106.324-50.073C381.98,255.496,380.632,255.669,379.234,255.641z" />
                        <path className="white" fill="#d2d6e8" d="M269.241,308.966h-8.828c-7.313,0-13.241-5.929-13.241-13.241l0,0  c0-7.313,5.929-13.241,13.241-13.241h8.828c7.313,0,13.241,5.929,13.241,13.241l0,0  C282.483,303.037,276.554,308.966,269.241,308.966z" />
                    </g>
                </svg>
            </div>
            Electronic Caregiver Support.
        </div>
    </div>

		);
	}
}