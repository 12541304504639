//AsthmaVitalsInterface.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import { PulseOxTutorialImageOne, PulseOxTutorialImageTwo, ReadyAddisonButton } from '../components';

export default class AsthmaVitalsUserInterface extends React.Component {
	getInitialState() {
		return {
			on: false,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

       // this.state.on = this.props.on;
        Hub.listen("toggleAsthmaVitalsUserInterface", this);
        Hub.listen("PulseOxTutorial", this);
        Hub.listen("PulseOxTutorial2", this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }
    toggle() {
        this.setState({on: !this.state.on});
    }
    onHubCapsule( capsule ) {
        const { channel, payload } = capsule;  //eslint-disable-line no-unused-vars

        if(channel === "PulseOxTutorial") {
            this.setState({on: true});
        }
        if(channel === "PulseOxTutorial2") {
            this.setState({on: true});
        }
    }

	render() {
      return (
		<div className={this.state.on ? "absolute" : "hidden"}>
            <PulseOxTutorialImageOne />
            <PulseOxTutorialImageTwo />
            <ReadyAddisonButton />
		</div>
	  );
	}
}