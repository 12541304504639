import React, {Component} from 'react';
import { Hub } from 'aws-amplify';

export default class HelpTab extends Component {
	getInitialState() {
		return {
		  on: false
		};
	}

	constructor(props) {
		super(props);
    	this.state = this.getInitialState();

    	Hub.listen("UIReset", this);
    	Hub.listen("helpbtn", this);
    	Hub.listen("closeHelp", this);

    	this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
	}	

	componentDidMount() {

	}

	componentDidUpdate() {

	}

	show() {
    	this.setState({on: true});
  	}


  	hide() {
    	this.setState({on: false});
  	}

  	reset() {
    	this.setState(this.getInitialState());
  	}

	onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

		if(channel === "UIReset") {
      		this.reset();
    	}

		else if(channel === "helpbtn") {
			this.show();
		}

		else if(channel === "closeHelp") {
			this.hide();
		}
	}


	render() {
		return ( 
			<div className={ this.state.on ? "helpTab" : "hidden"} id="helpTab">
			 
			<button className="btnrefreshpage" id="btnrefreshpage" onClick={(evt)=>document.location.replace('http://www.helloaddison.com')}>&nbsp;Refresh&nbsp;</button>

			<button className="btnclosehelp" id="btnclosehelp">&nbsp;Close&nbsp;</button>

			<button className="btnRR" id="btnRR">&nbsp;Rapid Response&nbsp;</button>

			<button className="btnCS" id="btnCS">&nbsp;Customer Support&nbsp;</button>

			<button className="btnPMD" id="btnPMD">&nbsp;Pocket MD&nbsp;</button>
			    
			</div>
		);
	}
}

