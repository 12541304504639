//AppSplashScreen.jsx

import React, {Component} from 'react';
import { Hub } from 'aws-amplify';

export default class AppSplashScreen extends Component {
  getInitialState() {
    return {
      on: true,
      url: 'https://d3o9tq5hgar52d.cloudfront.net/AddisonCare_Logo_Animated_SplashScreen.mp4'
    }
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    Hub.listen("sceneLoaded", this );
    Hub.listen("IntroSceneLoaded", this);
    Hub.listen("hideSplashScreen", this);

    console.log("in App constructor, this.state:", this.state);

    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  } //end ctor


  toggle() {
    this.setState({on: !this.state.on }); //toggle 
  }

  show() {
    this.setState({on: true});
  }

  hide() {
  	this.setState({on: false});
  }

  reset() {
    this.setState(this.getInitialState())
  }

  componentDidMount() {
  	let splashVideo = document.getElementById('splash-container');

  	splashVideo.onended = (event) => {
  		this.setState({on: false});
  		Hub.dispatch('splashFinished', {});
  	}
  }

  onHubCapsule(capsule) {
  	const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

  	if(channel === "hideSplashScreen" || channel === "IntroSceneLoaded") {
  		setTimeout(this.hide, 50);
  	} 
  }


  render() {
    return ( 
    	<div className={ this.state.on ? "Video-Container" : "hidden"}>
	    	<video style={{zIndex: "100000"}} id={"splash-container"} autoPlay muted>
	    		<source src={this.state.url} type={"video/mp4"} />
	    	</video>
    	</div>
    );
  }
};
