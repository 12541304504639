import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class ReadyAddisonButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("showReadyAddison", this);
        Hub.listen("ReadyAddison", this);


        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.handleClick = this.handleClick.bind(this);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset" || channel === "SceneReset") {
           this.reset();
        }

        else if(channel === "ReadyAddison" || channel === "wakeWord") {
          this.hide();
        }

        else if(channel === "showReadyAddison") {
          this.show();
        }
    }


    handleClick(event) {
      event.preventDefault();
      this.hide();
      window.amplifySceneLoader.emit("wakeWord");
    }


    render() {
        return (

            <button className={this.state.on ? "btnLarge" : "hidden"} onClick={this.handleClick} id="readyaddison">

                Ready Addison

            </button>



        );
    }
}
