import React from 'react';
import { Hub } from 'aws-amplify';
import { IdeasButton, NextIdeaButton, ListButton, NextButton, PlateProportions, MealIdeasOne, MealIdeasTwo, FoodListOne, FoodListTwo, NutritionFinishedButton  } from '../components';

export default class NutritionSceneUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("showNutritionUserInterface", this);
        Hub.listen("playNutritionScene", this);

        this.onHubCapsule = this.onHubCapsule.bind(this);

    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    toggle() {
        this.setState({on: !this.state.on});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "showNutritionUserInterface" || channel === "playNutritionScene") {
            this.setState({on: true});
        }
    }

 render() {
      return (
        <div className={this.state.on ? "absolute" : "hidden"}>
            <PlateProportions />
            <MealIdeasOne />
            <MealIdeasTwo />
            <FoodListOne />
            <FoodListTwo />
            <IdeasButton />
            <ListButton />
            <NextIdeaButton />
            <NextButton />
            <NutritionFinishedButton/>
        </div>
      );
 }
}