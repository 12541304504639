import React, { Component } from 'react';
// import { Hub } from 'aws-amplify';
import $ from 'jquery';

export default class UtterancesList extends Component {
	getInitialState() {
		return {
			on: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.state.on = this.props.on || this.state.on;
    }

    componentDidMount() {
	    (function() {

	        var utterances = $(".utterances"); //variables
	        var quoteIndex = -1;
	        
	        function showNextQuote() {
	            ++quoteIndex;  //increasing index
	            utterances.eq(quoteIndex % utterances.length) //items ito animate?
	                .fadeIn(500) //fade in time
	                .delay(2000) //time until fade out
	                .fadeOut(500, showNextQuote); //fade out time, recall function
	        }
	        showNextQuote();  
	    })();
	}

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }


    render() {
        return (
			<div className={this.state.on ? "" : "hidden"} id="youCanSay">You can say: "Hey Addison, show my 
				  <span className="utterances">&nbsp;heart rate."</span>
				  <span className="utterances">&nbsp;oxygen levels."</span>
				  <span className="utterances">&nbsp;blood pressure."</span>
				  <span className="utterances">&nbsp;weight."</span>
				  <span className="utterances">&nbsp;temperature."</span>
			</div>
        );
    }
}