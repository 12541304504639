import React from 'react';
import { Hub } from 'aws-amplify';
import { SummaryDiagram, ReadyButton, VitalsFinishedButton } from '../components';
import { AsthmaVitalsUserInterface, DiabetesVitalsUserInterface, HeartDiseaseVitalsUserInterface } from '../containers';

export default class VitalsUserInterface extends React.Component {
	getInitialState() {
		return {
			on: true,
            summaryDiagramOn: true,
            asthmaVitalsUserInterfaceOn: false,
            diabetesVitalsUserInterfaceOn: false,
            heartDiseaseVitalsUserInterfaceOn: false,
            readyButtonOn: false,
            vitalsFinishedButtonOn: false

		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getInitialState = this.getInitialState.bind(this);


        Hub.listen("showAsthmaVitalsUserInterface", this);
        Hub.listen("toggleAsthmaVitalsUserInterface", this);
        
        Hub.listen("showDiabetesVitalsUserInterface", this);
        Hub.listen("toggleDiabetesVitalsUserInterface", this);

        Hub.listen("showHeartDiseaseVitalsUserInterface", this);
        Hub.listen("toggleHeartDiseaseVitalsUserInterface", this);

        Hub.listen("playVitalsSceneDiabetes", this);
        Hub.listen("playVitalsSceneAsthma", this);
        Hub.listen("playVitalsSceneHeartDisease", this);

        this.showAsthmaVitalsUserInterface = this.showAsthmaVitalsUserInterface.bind(this);
        this.showDiabetesVitalsUserInterface = this.showDiabetesVitalsUserInterface.bind(this);
        this.showHeartDiseaseVitalsUserInterface = this.showHeartDiseaseVitalsUserInterface.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
        this.setState({on: true});
    }


    hide() {
        this.setState({on: false});
    }

    toggle() {
        this.setState({on: !this.state.on});
    }

    toggleAsthmaVitalsUserInterface() {
        this.setState({asthmaVitalsUserInterfaceOn: !this.state.asthmaVitalsUserInterfaceOn});
    }

    toggleDiabetesVitalsUserInterface() {
        this.setState({diabetesVitalsUserInterfaceOn: !this.state.diabetesVitalsUserInterfaceOn});
    }

    toggleHeartDiseaseVitalsUserInterfaceOn() {
        this.setState({heartDiseaseVitalsUserInterfaceOn: !this.state.heartDiseaseVitalsUserInterfaceOn});
    }

    showAsthmaVitalsUserInterface() {
        this.setState({asthmaVitalsUserInterfaceOn: true});
        this.setState({showDiabetesVitalsUserInterface: false});        //only one can be showing at a time, enforce this constraint
        this.setState({showHeartDiseaseVitalsUserInterface: false});
    }

    showDiabetesVitalsUserInterface() {
        this.setState({asthmaVitalsUserInterfaceOn: false});
        this.setState({showDiabetesVitalsUserInterface: true});
        this.setState({showHeartDiseaseVitalsUserInterface: false});
    }

    showHeartDiseaseVitalsUserInterface() {
        this.setState({asthmaVitalsUserInterfaceOn: false});
        this.setState({showDiabetesVitalsUserInterface: false});
        this.setState({showHeartDiseaseVitalsUserInterface: true});
    }

    toggleStateByKey(key) {
        this.setState({key: !this.state[key]});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "toggleAsthmaVitalsUserInterface") {
            this.setState({asthmaVitalsUserInterfaceOn: !(this.state.asthmaVitalsUserInterfaceOn)})
        }

        else if(channel === "toggleDiabetesVitalsUserInterface") {
            this.setState({diabetesVitalsUserInterfaceOn: !(this.state.diabetesVitalsUserInterfaceOn)})
        }

        else if(channel === "toggleHeartDiseaseVitalsUserInterface") {
            this.setState({heartDiseaseVitalsUserInterfaceOn: !(this.state.heartDiseaseVitalsUserInterfaceOn)})
        }

        else if(channel === "showAsthmaVitalsUserInterface" || channel === "playVitalsSceneAsthma") {
            this.showAsthmaVitalsUserInterface();
        }

        else if(channel === "showDiabetesVitalsUserInterface" || channel === "playVitalsSceneDiabetes") {
            this.showDiabetesVitalsUserInterface();
        }

        else if(channel === "showHeartDiseaseVitalsUserInterface" || channel === "playVitalsSceneHeartDisease") {
            this.showHeartDiseaseVitalsUserInterface();
        }

        else if(channel === "showVitalsUserInterface") {
            this.setState({on: true});
        }

        else if(channel === "toggleVitalsUserInterface") {
            this.setState({on: !(this.state.on)});
        }

        else if(channel === "showSummaryDiagram") {
            this.setState({summaryDiagramOn: true});
        }

        else if(channel === "toggleSummaryDiagram") {
            this.setState({summaryDiagramOn: !(this.state.summaryDiagramOn)});
        }
    }

	render() {
      return (
		<div className={this.state.on ? "absolute" : "hidden"}>
			<SummaryDiagram on={this.state.summaryDiagramOn} />
            <ReadyButton on={this.state.readyButtonOn} />
            <VitalsFinishedButton on={this.state.vitalsFinishedButtonOn} />
            <AsthmaVitalsUserInterface on={this.state.asthmaVitalsUserInterfaceOn} />
            <DiabetesVitalsUserInterface on={this.state.diabetesVitalsUserInterfaceOn} />
            <HeartDiseaseVitalsUserInterface on={this.state.heartDiseaseVitalsUserInterfaceOn} />
		</div>
	  );
	}
}
