import React from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader,{ sceneConfigs } from '../AmplifySceneLoader';
import './css/homePage.css';


export default class AppSceneSelectMenu extends React.Component {
    getInitialState() {
        return {
            on: false,
            sceneConfig: sceneConfigs.introScene
        }
    }

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("UIReset", this);
        Hub.listen("IntroSceneLoaded", this, "AppSceneSelectMenu");
        Hub.listen("PlayingNewScene", this, "AppSceneSelectMenu");
        Hub.listen("SceneReset", this, "AppSceneSelectMenu");

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        this.dispatchAsthma = this.dispatchAsthma.bind(this);
        this.dispatchDiabetes = this.dispatchDiabetes.bind(this);
        this.dispatchHeartDisease = this.dispatchHeartDisease.bind(this);

        this.dispatchNutrition = this.dispatchNutrition.bind(this);
        // this.dispatchPlayHelpScene = this.dispatchPlayHelpScene.bind(this);
        this.dispatchAsthmaMedReminder = this.dispatchAsthmaMedReminder.bind(this);
        this.dispatchHeartDiseaseMedReminder = this.dispatchHeartDiseaseMedReminder.bind(this);
        this.dispatchDiabetesMedReminder = this.dispatchDiabetesMedReminder.bind(this);

        this.dispatchNutrition = this.dispatchNutrition.bind(this);
        this.dispatchGaitDiabetes = this.dispatchGaitDiabetes.bind(this);
        this.dispatchGaitHeartDisease = this.dispatchGaitHeartDisease.bind(this);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();

    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }



    dispatchAsthma(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayAsthmaVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsAsthma");
        this.hide();
    }

    dispatchNutrition(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayNutritionScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchNutrition");
        this.hide();
    }

    dispatchActivityMonitoring(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayActivityMonitoringScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchActivity");
        this.hide();
    }

    dispatchGaitDiabetes(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayGaitDiabetesScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGaitDiabetes");
        this.hide();
    }

    dispatchGaitHeartDisease(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayGaitHeartDiseaseScene();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGaitHeartDisease");
        this.hide();
    }

    dispatchCoping(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayCopingScene();
        //window.amplifySceneLoader.state.sceneController.emit("SceneSwitchGait");
        this.hide();
    }


    dispatchDiabetes(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayDiabetesVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsDiabetes");
        this.hide();
    }


    dispatchHeartDisease(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayHeartDiseaseVitals();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchVitalsHeartDisease");
        this.hide();
    }

    dispatchAsthmaMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayAsthmaMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderAsthma");
        this.hide();
    }

    dispatchDiabetesMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayDiabetesMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderDiabetes");
        this.hide();
    }

    dispatchHeartDiseaseMedReminder(event) {
        event.preventDefault();
        window.amplifySceneLoader.dispatchPlayHeartDiseaseMedReminder();
        window.amplifySceneLoader.state.sceneController.emit("SceneSwitchMedReminderHeartDisease");
        this.hide();
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset" || channel === "SceneReset") {
           this.reset();
        }

        else if (channel === "IntroSceneLoaded") {
            this.show();
        }

        else if(channel === "ShowThisComponent") {
            this.show();
        }

        else if(channel === "PlayingNewScene" ) {
          this.hide();
        }

        else if(channel === "ToggleThisComponent") {
          this.setState({on: !(this.state.on)});
        }
    }

    render() {
    	return this.props.on ? (
    		<div id="" className={"homeContainer"}>
			  <div id="" className="pocBanner">
					Asthma Patient: "Mr. Anderson"
			  </div>
			  <div className="body">
					  <button className="btnSmallWide" id="" onClick={this.dispatchAsthma}>Measure my Blood Oxygen</button>
			      <button className="btnSmallWide" id="" onClick={this.dispatchAsthmaMedReminder}>What are Anderson's Medications?</button>
			  </div>
			  <div id="pocBanner1" className="pocBanner1">
					Diabetes Patient: "John"
			  </div>
			  <div className="body">
					  <button className="btnSmallWide" id="" onClick={this.dispatchDiabetes}>Test My Blood Sugar</button>
			      <button className="btnSmallWide" id="" onClick={this.dispatchPlayNutritionScene}>Tell Me Nutrition Recommendations</button>
			      <button className="btnSmallWide" id="" onClick={this.dispatchDiabetesMedReminder}>Show me John's next Medication</button>
			      <button onClick={this.dispatchCoping} className="btnSmallWide" id="" >Let's Talk About My Feelings</button>
			  </div>
			  <div id="" className="pocBanner1">
					Heart Disease Patient: "Mary"
			  </div>
			  <div className="body">
				  <button onClick={this.dispatchHeartDisease} className="btnSmallWide" id="">Check My Blood Pressure</button>
			      <button onClick={this.dispatchHeartDiseaseMedReminder} className="btnSmallWide" id="">Mary Has What Medication Next?</button>
			      <button onClick={this.dispatchGaitHeartDisease} className="btnSmallWide" id="">Analyze the Way I Walk</button>
			  </div>
			</div>

		) : (null)
    }
}